<template>
  <!-- Allocate chart inside div container -->
  <div style="height: 100%; width: 100%;">
    <div id="controls"
         style="height: 3%; color:Blue; font-size: 20px; text-align: center">
      <div style="display: flex; float: left">
        <a href="" id="data_prev" style="color: green"><<<</a>
      </div>
      <div style="display: flex; float: right">
        <a href="" id="data_next" style="color: green">>>></a>
      </div>
      <div id="div_timeframes"
           style="height: auto; background: black; color:Blue; font-size: 20px; text-align: center"></div>
    </div>
    <div id="div_tickers"
         style="height: 3%; background: black; color:#CCCC00; font-size: 20px; text-align: center"></div>
    <div :id="chartId" style="height: 94%"></div>
  </div>
</template>

<script>
import {
  AxisScrollStrategies,
  AxisTickStrategies, ColorHEX,
  ColorRGBA,
  emptyFill, emptyLine, ImageFill, ImageFitMode,
  lightningChart,
  LUT, MouseStyles,
  OHLCFigures, OnScreenMenuButtonShape,
  PalettedFill, SolidFill, SolidLine,
  Themes, translatePoint, UIBackgrounds, UIDraggingModes, UIElementBuilders, UIOrigins,
} from '@arction/lcjs'
import {get} from "https";
import axios from "axios";
import FileSaver from 'file-saver';

const percentile = require('stats-percentile');

export default {
  name: 'Chart',
  // props: ['points'],
  data() {
    const license_key = typeof process.env.VUE_APP_LICENSE === 'undefined' ? '' : process.env.VUE_APP_LICENSE;
    this.lcjs = lightningChart({
      license: license_key,
      overrideInteractionMouseButtons: {
        chartXYPanMouseButton: 0,
        chartXYRectangleZoomFitMouseButton: 2,
      },
    })

    // Add the chart to the data in a way that Vue will not attach it's observers to it.
    // If the chart variable would be added in the return object, Vue would attach the observers and 
    // every time LightningChart JS made a change to any of it's internal variables, vue would try to observe the change and update.
    // Observing would slow down the chart a lot.
    this.tickers = []
    this.tickersStatus = {}
    this.tf = 60
    this.timeStart = null
    this.vmin = 0
    this.chart = null
    this.dataBook = {}
    this.dataCandles = []
    this._dataQueue = []
    this.pointSeries = null
    this.candleSeries = null
    this.tickerColorLoading = "#CCCC00"
    this.tickerColorReady = "#50C878"
    this.tickerColorOff = "#C70039"
    this.api = 'https://api.trevax.com'
    this.boundary_percent = 50
    this.book_boundary_percent = 0
    this.endX = 0
    this.dragX = false
    this.xMin = 0
    this.xMax = 0
    this.yMin = 0
    this.yMax = 0
    this.tsi = 0
    this.project_id = 0
    this.reload = 0;
    this.pub = false;
    this.wm = false;
    this.oldStyle = null;
    this.buttons = {};
    this.db = 'trevax';
    return {
      chartId: null,
    }
  },
  methods: {
    addMenu() {
      const rgb2hex = (rgb) => `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/).slice(1).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`
      let div_timeframes = document.getElementById('div_timeframes')
      div_timeframes.innerHTML = "";
      [1, 5, 10, 15, 30, 60, 120, 180, 240, 720, 1440].forEach(tf => {
        let a = document.createElement('a');
        let link = document.createTextNode(tf.toString());
        a.appendChild(link);
        a.href = "#";
        if (this.tf === tf)
          a.style.color = 'green'
        else
          a.style.color = 'blue'
        a.style.margin = '5px'
        a.id = `TF_${tf}`
        a.onclick = function () {
          let tf = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('tf', tf);
          window.open(href.toString(), "_self")
          return false
        }
        div_timeframes.appendChild(a);
      })

      {
        let href = new URL(document.URL);
        let a = document.createElement('a');
        let link = document.createTextNode('SAVE');
        a.appendChild(link);
        a.href = "#";

        let bp = 0;
        if (href.searchParams.has('bp')) {
          bp = parseInt(href.searchParams.get('bp'))
        }

        a.style.color = '#f028ff'
        a.style.margin = '5px'
        a.onclick = function () {
          let [exchange, market_type, tbase, tquote] = this.tickers[0].split('_')
          let ts = Math.trunc(Date.now() / 1000)
          let postfix = 'orig'
          if (this.wm) {
            postfix = 'wm'
          }
          let blob = new Blob([this.chart.engine.captureFrame()], {type: "image/png"});
          FileSaver.saveAs(blob, `${ts}_${tbase}_${tquote}_${postfix}`);
          return false
        }.bind(this)
        div_timeframes.appendChild(a);

      }

      let div_tickers = document.getElementById('div_tickers')
      div_tickers.innerHTML = "";

      this.tickers.forEach(ticker => {
        let a = document.createElement('a');
        let link = document.createTextNode(ticker.toString());
        a.appendChild(link);
        a.href = "#";
        a.id = `LINK_${ticker.toString()}`
        a.style.color = this.tickerColorLoading
        a.style.margin = '10px'
        a.onclick = function () {
          let old_state = 0
          if (rgb2hex(a.style.color).toUpperCase() === this.tickerColorReady || rgb2hex(a.style.color).toUpperCase() === this.tickerColorLoading)
            old_state = 1
          this.tickersStatus[ticker] = old_state === 1 ? 0 : 1
          let enabledTickers = []
          for (const [tkr, enabled] of Object.entries(this.tickersStatus)) {
            if (enabled)
              enabledTickers.push(tkr)
          }
          this.applyBooks(enabledTickers)
          return false
        }.bind(this)
        div_tickers.appendChild(a);
      })

      let href = new URL(document.URL);
      let link_prev = document.getElementById('data_prev')
      let link_next = document.getElementById('data_next')

      let pte = Math.round((parseInt(this.timeStart) + (250 * this.tf * 60)) / (60 * this.tf)) * (60 * this.tf)
      let pts = Math.round((pte - (this.historyLength * this.tf * 60)) / (60 * this.tf)) * (60 * this.tf)
      href.searchParams.set('time_start', pts.toString())
      href.searchParams.set('time_end', pte.toString())
      link_prev.href = href

      let nts = Math.round((parseInt(this.timeEnd) - (250 * this.tf * 60)) / (60 * this.tf)) * (60 * this.tf)
      let nte = Math.round((nts + (this.historyLength * this.tf * 60)) / (60 * this.tf)) * (60 * this.tf)
      if (nte >= Math.round(new Date().getTime() / 1000 / (60 * this.tf)) * 60 * this.tf) {
        href.searchParams.delete('time_start')
        href.searchParams.delete('time_end')
      } else {
        href.searchParams.set('time_start', nts.toString())
        href.searchParams.set('time_end', nte.toString())
      }
      link_next.href = href
    },
    addWatermark() {
      if (null === this.oldStyle) {
        this.oldStyle = this.chart.getSeriesBackgroundFillStyle();
      }
      // https://javascript.plainenglish.io/how-to-build-an-image-watermarking-tool-with-html-canvas-9dd45bb4f13
      let [exchange, market_type, tbase, tquote] = this.tickers[0].split('_')
      let canvas = document.createElement("canvas");
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const getCoordinates = (position, imageWidth, imageHeight, canvasWidth, canvasHeight) => {
        const xCenter = canvasWidth / 2 - imageWidth / 2;
        const yCenter = canvasHeight / 2 - imageHeight / 2;
        switch (position) {
          case "top-left":
            return [0, 0];
          case "top-center":
            return [xCenter, 0];
          case "top-right":
            return [canvasWidth - imageWidth, 0];
          case "bottom-left":
            return [0, canvasHeight - imageHeight];
          case "bottom-center":
            return [xCenter, canvasHeight - imageHeight];
          case "bottom-right":
            return [canvasWidth - imageWidth, canvasHeight - imageHeight];
          case "center":
            return [xCenter, yCenter];
          case "center-left":
            return [0, yCenter]
          case "center-right":
            return [canvasWidth - imageWidth, yCenter]
          default:
            return [0, 0];
        }
      };
      const watermark_text = (canvas, text) => {
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = '#bdafaf';
        ctx.font = '64px Helvetica';
        ctx.textAlign = 'center';
        ctx.fillText(text, canvas.width / 2, canvas.height / 2);

      }
      const watermark_img = (canvas, watermarkImage, position, alpha) => {
        const ctx = canvas.getContext("2d");
        // ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.globalAlpha = alpha;
        const [x, y] = getCoordinates(
            position,
            watermarkImage.width,
            watermarkImage.height,
            canvas.width,
            canvas.height
        );
        ctx.drawImage(
            watermarkImage,
            x,
            y,
            watermarkImage.width,
            watermarkImage.height
        );
      };

      const watermarkImage = new Image()
      watermarkImage.crossOrigin = ''
      watermarkImage.src = '/chart_logo.png'
      watermarkImage.onload = () => {
        watermark_img(canvas, watermarkImage, 'top-left', 0.6);
        watermark_img(canvas, watermarkImage, 'top-center', 0.6);
        watermark_img(canvas, watermarkImage, 'top-right', 0.6);
        watermark_img(canvas, watermarkImage, 'bottom-left', 0.6);
        watermark_img(canvas, watermarkImage, 'bottom-center', 0.6);
        watermark_img(canvas, watermarkImage, 'bottom-right', 0.6);
        watermark_img(canvas, watermarkImage, 'center-left', 0.6);
        watermark_img(canvas, watermarkImage, 'center-right', 0.6);
        watermark_text(canvas, `${tbase}${tquote}, ${this.tf}`)
        const image = new Image();
        image.crossOrigin = ''
        image.src = canvas.toDataURL();
        image.onload = () => {
          canvas.remove();
          this.chart.setSeriesBackgroundFillStyle(
              new ImageFill({
                source: image,
                fitMode: ImageFitMode.Fit,
              }),
          )
        }
      }
    },
    addKbShortcuts() {
      window.document.onkeyup = function (e) {
        if (e.code === 'KeyM' && e.shiftKey) {
          this.pub = !this.pub;
          if (this.pub) {
            window.document.getElementById('controls').style.display = 'none';
            window.document.getElementById('div_tickers').style.display = 'none';
            window.document.getElementById(this.chartId).style.height = '100%';
            this.chart.engine.layout();
          } else {
            window.document.getElementById('controls').style.display = 'block';
            window.document.getElementById('div_tickers').style.display = 'block';
            window.document.getElementById(this.chartId).style.height = '94%';
            this.chart.engine.layout();
          }
        } else if (e.code === 'KeyW' && e.shiftKey) {
          this.wm = !this.wm;
          if (this.wm) {
            this.addWatermark();
          } else {
            this.chart.setSeriesBackgroundFillStyle(this.oldStyle)
          }
        }
      }.bind(this);
    },
    saveMarkup() {
      let tmp = {
        'project_id': this.project_id,
        'tf': this.NN_PARAMS['tf'],
        'tsi': this.NN_PARAMS['tsi'],
        'ticker': this.NN_PARAMS['ticker'],
        'stop': this.NN_PARAMS['stop'],
        'target': this.NN_PARAMS['target'],
        'accum_start': this.NN_PARAMS['accum_start'],
        'accum_end': this.NN_PARAMS['accum_end'],
        'direction': this.NN_PARAMS['direction'],
        'status': 1
      }
      if (this.NN_PARAMS['direction'] === 0) {
        tmp['stop'] = 0;
        tmp['target'] = 0;
        tmp['accum_start'] = 0;
        tmp['accum_end'] = 0;
      }
      axios.post('https://api.trevax.com/tools/markup', tmp, {}).then(function (response) {
        alert('Saved');
      })
    },
    loadMarkup() {
      if (this.tsi === 0)
        return;
      const ticker = this.tickers[0];
      const url_markup = `${this.api}/tools/markup?ticker=${ticker}&tf=${this.tf}&tsi=${this.tsi}&rnd=${Math.random()}`;
      let body = ''
      get(url_markup, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              const data = JSON.parse(body);
              this.applyMarkup(data)
            }.bind(this)
        )
      }.bind(this));
    },
    calcSLTP() {
      const pos_amount = 100000;
      let avg_price = 0;
      if (this.NN_PARAMS['accum_start'] > 0 && this.NN_PARAMS['accum_end']) {
        let start = this.NN_PARAMS['accum_start'];
        let end = this.NN_PARAMS['accum_end'];
        avg_price = ((Math.max(start, end) - Math.min(start, end)) / 2) + Math.min(start, end);
      }

      let loss = 0;
      if (this.NN_PARAMS['stop'] > 0 && avg_price > 0) {
        loss = Math.abs(pos_amount - Math.trunc(Math.abs(pos_amount / avg_price * this.NN_PARAMS['stop'])));
      }

      let profit = 0;
      if (this.NN_PARAMS['target'] > 0 && avg_price > 0) {
        profit = Math.abs(pos_amount - Math.trunc(Math.abs(pos_amount / avg_price * this.NN_PARAMS['target'])));
      }

      if (profit > 0) {
        this.buttons['profit'].setText(`Profit: ${profit}`)
      }
      if (loss > 0) {
        this.buttons['loss'].setText(`Loss: ${loss}`)
      }
    },
    applyMarkup(data) {
      if (this.tsi > 0) {
        const tsiLine = this.chart.getDefaultAxisX().addConstantLine(false)
            .setName('tsi')
            .setValue(this.tsi * this.tf * 60 * 1000)
            .setStrokeStyle(new SolidLine({
              thickness: 4,
              fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(60)
            }))
            .setMouseInteractions(false)


        const price_step = (this.yMax - this.yMin) / 10;
        let pos_stop = this.yMax + (price_step * 2);
        let pos_target = this.yMax + (price_step * 3);
        let pos_accum_start = this.yMin - price_step;
        let pos_accum_end = this.yMin - (price_step * 2);

        for (const markup of data) {
          if (markup['tsi'] === this.tsi) {
            pos_stop = markup['stop'] > 0 ? markup['stop'] : pos_stop;
            pos_target = markup['target'] > 0 ? markup['target'] : pos_target;
            pos_accum_start = markup['accum_start'] > 0 ? markup['accum_start'] : pos_accum_start;
            pos_accum_end = markup['accum_end'] > 0 ? markup['accum_end'] : pos_accum_end;
            this.NN_PARAMS['direction'] = markup['direction'];
          }
        }

        const stopLine = this.chart.getDefaultAxisY().addConstantLine(true)
            .setName('stop')
            .setValue(pos_stop)
            .setStrokeStyle(new SolidLine({
              thickness: 4,
              fillStyle: new SolidFill({color: ColorHEX('#ff2525')})
            }))
            .setMouseInteractions(true)
        if (this.NN_PARAMS['stop'] > 0) {
          stopLine.setValue(this.NN_PARAMS['stop'])
        }
        stopLine.onValueChange((line, value) => {
          this.NN_PARAMS['stop'] = value;
          this.calcSLTP();
        })
        this.NN_PARAMS['stop'] = pos_stop;

        const targetLine = this.chart.getDefaultAxisY().addConstantLine(true)
            .setName('target')
            .setValue(pos_target)
            .setStrokeStyle(new SolidLine({
              thickness: 4,
              fillStyle: new SolidFill({color: ColorHEX('#42ff25')})
            }))
            .setMouseInteractions(true)
        if (this.NN_PARAMS['target'] > 0) {
          targetLine.setValue(this.NN_PARAMS['target'])
        }
        targetLine.onValueChange((line, value) => {
          this.NN_PARAMS['target'] = value;
          this.calcSLTP();
        })
        this.NN_PARAMS['target'] = pos_target;

        const accumBand = this.chart.getDefaultAxisY().addBand(true)
            .setName('accumulation')
            .setValueStart(pos_accum_start)
            .setValueEnd(pos_accum_end)
            .setStrokeStyle(new SolidLine({thickness: 2, fillStyle: new SolidFill({color: ColorHEX('#8cffd2')})}))
            .setFillStyle(new SolidFill({color: ColorHEX('#8cffd2')}).setA(95))
            .setMouseInteractions(true)
        this.NN_PARAMS['accum_start'] = pos_accum_start;
        this.NN_PARAMS['accum_end'] = pos_accum_end;
        if (this.NN_PARAMS['accum_start'] > 0) {
          accumBand.setValueStart(this.NN_PARAMS['accum_start'])
        }
        if (this.NN_PARAMS['accum_end'] > 0) {
          accumBand.setValueEnd(this.NN_PARAMS['accum_end'])
        }
        accumBand.onValueChange((band, start, end) => {
          this.NN_PARAMS['accum_start'] = start;
          this.NN_PARAMS['accum_end'] = end;
          this.calcSLTP();
        })

        Object.keys(this.buttons).forEach(function (key) {
          this.buttons[key].setBackground(bg => bg
              .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
          )
        }.bind(this));

        switch (this.NN_PARAMS['direction']) {
          case 1:
            this.buttons['up'].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#42ff25'))))
            );
            break;
          case 0:
            this.buttons['none'].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#42ff25'))))
            );
            break;
          case -1:
            this.buttons['down'].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#42ff25'))))
            )
        }
      }
      this.calcSLTP();
    },
    scaleY() {
      const boundary = this.yMax / 100 * this.boundary_percent
      const ybMax = (this.yMax + boundary)
      const ybMin = (this.yMin - boundary)
      ybMin < 0 ? 0 : ybMin
      this.chart.getDefaultAxisY().setScrollStrategy(undefined).setInterval(ybMin, ybMax)
    },
    scaleX() {
      const maxX = this.xMax + (this.tf * 60 * 1000 * 15)
      this.chart.getDefaultAxisX().setScrollStrategy(undefined).setInterval(this.xMin, maxX)
    },
    applyCandles() {
      this.dataCandles.forEach(candle => {
        const ts = candle[0]
        const high = candle[2]
        const low = candle[3]
        if (this.xMin === 0 || this.xMin > ts)
          this.xMin = ts
        if (this.xMax < ts)
          this.xMax = ts

        if (this.yMin === 0 || this.yMin > low)
          this.yMin = low
        if (this.yMax < high)
          this.yMax = high
      })
      this.scaleY()
      this.scaleX()
      this.candleSeries.clear()
      this.candleSeries.add(this.dataCandles)

      if (this.book_boundary_percent > 0) {
        const book_boundary = this.yMax / 100 * this.book_boundary_percent
        this.price_max = this.yMax + book_boundary
        this.price_min = this.yMin - book_boundary
        this.price_min < 0 ? 0 : this.price_min
      }

      this.loadMarkup();
      this.loadBooks(this.tickers, this.timeStart, this.timeEnd)
    },
    applyBooks() {
      for (const [ticker, status] of Object.entries(this.tickersStatus)) {
        let el = document.getElementById(`LINK_${ticker.toString()}`)
        if (status === 0)
          el.style.color = this.tickerColorOff
        else
          el.style.color = this.tickerColorLoading
      }
      let totalBook = {}
      for (const [ticker, status] of Object.entries(this.tickersStatus)) {
        if (ticker in this.dataBook && status === 1) {
          for (const [ts, data] of Object.entries(this.dataBook[ticker])) {
            for (const [price, volume] of Object.entries(data)) {
              if (!(ts in totalBook))
                totalBook[ts] = {}
              if (!(price in totalBook[ts]))
                totalBook[ts][price] = volume
              else
                totalBook[ts][price] += volume
            }
          }
          let el = document.getElementById(`LINK_${ticker.toString()}`)
          el.style.color = this.tickerColorReady
        }
      }

      let vol_all = []
      let max_vol = 0
      let new_book = []
      for (const [ts, data] of Object.entries(totalBook)) {
        for (const [price, volume] of Object.entries(data)) {
          if (volume > max_vol)
            max_vol = volume
          vol_all.push(volume)
          new_book.push({x: ts, y: price, value: volume})
        }
      }

      const p_low = percentile(vol_all, 75)
      const p_high = percentile(vol_all, 99)
      const p_med = ((p_high - p_low) / 2) + p_low
      this.pointSeries.setPointFillStyle(new PalettedFill({
        lookUpProperty: 'value',
        lut: new LUT({
          interpolate: true,
          steps: [
            {value: 0, color: ColorRGBA(0, 0, 0, 8)},
            {value: p_med / 2, color: ColorRGBA(252, 221, 0, 64)},
            {value: p_med, color: ColorRGBA(252, 221, 0, 128)},
            {value: p_high, color: ColorRGBA(250, 0, 0, 200)},
          ]
        })
      }));

      this.pointSeries.clear()
      this.pointSeries.add(new_book)
      this.candleSeries.clear()
      this.candleSeries.add(this.dataCandles)
    },
    loadBooks(tickers, time_start, time_end) {
      tickers.forEach(ticker => {
        const rd_rule = this.rd === null ? '' : `&rd=${this.rd}`
        const url_book = `${this.api}/book/levels?ticker=${ticker}&tf=${this.tf}&time_start=${time_start}&time_end=${time_end}&vmin=${this.vmin}&pmin=${this.price_min}&pmax=${this.price_max}&db=${this.db}${rd_rule}`
        let body = ''
        get(url_book, function (res) {
          res.on('data', function (chunk) {
            body += chunk;
          });
          res.on('end', function () {
                if (!(ticker in this.dataBook)) {
                  this.dataBook[ticker] = {}
                }
                const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
                data.forEach(el => {
                  const x = el[0] * 1000
                  const y = el[1]
                  const v = el[2]
                  if (!(x in this.dataBook[ticker]))
                    this.dataBook[ticker][x] = {}
                  if (!(y in this.dataBook[ticker][x]))
                    this.dataBook[ticker][x][y] = v
                  else
                    this.dataBook[ticker][x][y] += v
                })
                this.pointSeries.setIndividualPointValueEnabled(true)
                this.pointSeries.setPointSize(3)
                this.applyBooks()
              }.bind(this)
          )
        }.bind(this));
      });
    },
    loadCandles(ticker, time_start, time_end) {
      const url_candles = `${this.api}/candles?ticker=${ticker}&tf=${this.tf}&time_start=${time_start}&time_end=${time_end}&db=${this.db}`
      let body = ''
      get(url_candles, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
              data.forEach(el => {
                const x = el[0] * 1000
                this.dataCandles.push([x, el[1], el[2], el[3], el[4]])
              })
              this.applyCandles()
            }.bind(this)
        )
      }.bind(this));
    },
    createChart() {
      // https://stackoverflow.com/questions/66839983/click-on-the-chart-and-drag-to-move-around-the-chart
      this.chart = this.lcjs.ChartXY({
        container: `${this.chartId}`, theme: Themes.darkGreen, defaultAxisY: {opposite: true,}
      })
      // this.chart.setTitle(this.tickers.join(' / '))
      this.chart.setTitleFillStyle(emptyFill)
      // https://stackoverflow.com/questions/59934089/how-to-disable-zooming-animation-on-loading-lightningchart-js
      this.chart.getDefaultAxisX().setAnimationZoom(undefined)
      this.chart.getDefaultAxisY().setAnimationZoom(undefined)
      let tickStrategyY = this.chart.getDefaultAxisY().getTickStrategy()
      this.chart.getDefaultAxisY().setTickStrategy(
          // Use Numeric TickStrategy as Base.
          tickStrategyY,
          // Use a mutator to modify the TickStrategy.
          (tickStrategy) => tickStrategy
              // Modify the Major Ticks for the TickStrategy.
              // Minor and Extreme TickStyles must be set separately.
              .setMajorTickStyle(visibleTicks => visibleTicks
                  .setLabelFont(font => font
                      .setSize(12)
                      .setWeight('lighter')
                  )
                  .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine)
              )
              .setMinorTickStyle(tickStrategy.getMajorTickStyle())
              .setMinorTickStyle(visibleTicks => visibleTicks
                  .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine))
      )
      this.chart.getDefaultAxisX().setTickStrategy(
          AxisTickStrategies.DateTime,
      )
      let tickStrategyX = this.chart.getDefaultAxisX().getTickStrategy()
      this.chart.getDefaultAxisX().setTickStrategy(
          // Use Numeric TickStrategy as Base.
          tickStrategyX,
          // Use a mutator to modify the TickStrategy.
          (tickStrategy) => tickStrategy
              // Modify the Major Ticks for the TickStrategy.
              // Minor and Extreme TickStyles must be set separately.
              .setMajorTickStyle(visibleTicks => visibleTicks
                  .setLabelFont(font => font
                      .setSize(12)
                      .setWeight('lighter')
                  )
                  .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine)
              )
              .setMinorTickStyle(tickStrategy.getMajorTickStyle())
              .setMinorTickStyle(visibleTicks => visibleTicks
                  .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine))
      )

      this.chart.getDefaultAxisY()
          .setScrollStrategy(undefined)
          .setChartInteractionFitByDrag(false)
          .setChartInteractionZoomByDrag(false)
          .setChartInteractionPanByDrag(false)
          .setChartInteractionZoomByWheel(false)
      this.chart.getDefaultAxisX()
          .setScrollStrategy(AxisScrollStrategies.fitting)

      this.chart.getDefaultAxisY().onScaleChange((start, end) => {
        if (start < 0)
          this.chart.getDefaultAxisY().setInterval(0, end, false, true)
      })

      this.chart.onSeriesBackgroundMouseDragStart(() => {
        this.dragX = true
      })
      this.chart.onSeriesBackgroundMouseDragStop(() => {
        this.dragX = false
        let interval = this.chart.getDefaultAxisX().getInterval()
        this.endX = interval.end
      })
      this.chart.getDefaultAxisX().onScaleChange((start, end) => {
        if (this.dragX === true) return;
        if (this.endX === 0) this.endX = end
        if (end === this.endX) return
        this.chart.getDefaultAxisX().setInterval(start, this.endX, false, true)
      })

      this.chart.getDefaultAxisX().onAxisInteractionAreaMouseDoubleClick(() => {
        this.scaleX()
      })
      this.chart.getDefaultAxisY().onAxisInteractionAreaMouseDoubleClick(() => {
        this.scaleY()
      })

      // POINT SERIES
      this.pointSeries = this.chart.addPointSeries();

      // CANDLE SERIES
      this.candleSeries = this.chart.addOHLCSeries({positiveFigure: OHLCFigures.Candlestick})
          .setFigureAutoFitting(false)

      this.addMenu();
      this.addKbShortcuts();

      if (this.tsi > 0) {
        let last_x = 8;
        let x_inc = 65;

        this.buttons['up'] = this.chart.addUIElement(UIElementBuilders.ButtonBox.setBackground(UIBackgrounds.Rectangle))
            .setPosition(translatePoint({
              x: last_x,
              y: this.chart.engine.container.getBoundingClientRect().height - 8
            }, this.chart.engine.scale, this.chart.uiScale))
            .setOrigin(UIOrigins.LeftTop)
            .setText('U')
            .setPadding({top: 5, right: 20, bottom: 5, left: 20})
            .setButtonOffSize(0)
            .setButtonOnSize(0)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
            )
        this.buttons['up'].onMouseClick((obj, event) => {
          Object.keys(this.buttons).forEach(function (key) {
            this.buttons[key].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
            )
            this.buttons['up'].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#42ff25'))))
            )
            this.NN_PARAMS['direction'] = 1;
          }.bind(this));
        });

        last_x += x_inc;
        this.buttons['none'] = this.chart.addUIElement(UIElementBuilders.ButtonBox.setBackground(UIBackgrounds.Rectangle))
            .setPosition(translatePoint({
              x: last_x,
              y: this.chart.engine.container.getBoundingClientRect().height - 8
            }, this.chart.engine.scale, this.chart.uiScale))
            .setOrigin(UIOrigins.LeftTop)
            .setText('N')
            .setPadding({top: 5, right: 20, bottom: 5, left: 20})
            .setButtonOffSize(0)
            .setButtonOnSize(0)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
            )
        this.buttons['none'].onMouseClick((obj, event) => {
          Object.keys(this.buttons).forEach(function (key) {
            this.buttons[key].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
            )
            this.buttons['none'].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#42ff25'))))
            )
            this.NN_PARAMS['direction'] = 0;
          }.bind(this));
        });

        last_x += x_inc;
        this.buttons['down'] = this.chart.addUIElement(UIElementBuilders.ButtonBox.setBackground(UIBackgrounds.Rectangle))
            .setPosition(translatePoint({
              x: last_x,
              y: this.chart.engine.container.getBoundingClientRect().height - 8
            }, this.chart.engine.scale, this.chart.uiScale))
            .setOrigin(UIOrigins.LeftTop)
            .setText('D')
            .setPadding({top: 5, right: 20, bottom: 5, left: 20})
            .setButtonOffSize(0)
            .setButtonOnSize(0)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
            )
        this.buttons['down'].onMouseClick((obj, event) => {
          Object.keys(this.buttons).forEach(function (key) {
            this.buttons[key].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
            )
            this.buttons['down'].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#42ff25'))))
            )
            this.NN_PARAMS['direction'] = -1;
          }.bind(this));
        });

        switch (this.NN_PARAMS['direction']) {
          case 1:
            this.buttons['up'].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#42ff25'))))
            );
            break;
          case 0:
            this.buttons['none'].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#42ff25'))))
            );
            break;
          case -1:
            this.buttons['down'].setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#42ff25'))))
            )
        }

        last_x += x_inc;
        const button_save = this.chart.addUIElement(UIElementBuilders.ButtonBox.setBackground(UIBackgrounds.Rectangle))
            .setPosition(translatePoint({
              x: last_x,
              y: this.chart.engine.container.getBoundingClientRect().height - 8
            }, this.chart.engine.scale, this.chart.uiScale))
            .setOrigin(UIOrigins.LeftTop)
            .setText('Save')
            .setPadding({top: 5, right: 20, bottom: 5, left: 20})
            .setButtonOffSize(0)
            .setButtonOnSize(0)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
            )
            .onMouseClick((event) => {
              this.saveMarkup()
            });

        last_x += x_inc * 3;
        this.buttons['loss'] = this.chart.addUIElement(UIElementBuilders.ButtonBox.setBackground(UIBackgrounds.Rectangle))
            .setPosition(translatePoint({
              x: last_x,
              y: this.chart.engine.container.getBoundingClientRect().height - 8
            }, this.chart.engine.scale, this.chart.uiScale))
            .setOrigin(UIOrigins.CenterTop)
            .setText('Loss: 0')
            .setPadding({top: 5, right: 20, bottom: 5, left: 20})
            .setButtonOffSize(0)
            .setButtonOnSize(0)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
            )

        last_x += x_inc * 3;
        this.buttons['profit'] = this.chart.addUIElement(UIElementBuilders.ButtonBox.setBackground(UIBackgrounds.Rectangle))
            .setPosition(translatePoint({
              x: last_x,
              y: this.chart.engine.container.getBoundingClientRect().height - 8
            }, this.chart.engine.scale, this.chart.uiScale))
            .setOrigin(UIOrigins.CenterTop)
            .setText('Profit: 0')
            .setPadding({top: 5, right: 20, bottom: 5, left: 20})
            .setButtonOffSize(0)
            .setButtonOnSize(0)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
            )

      }
      this.loadCandles(this.tickers[0], this.timeStart, this.timeEnd)
    },
  },
  async beforeMount() {
    // Generate random ID to us as the containerId for the chart and the target div id
    this.chartId = Math.trunc(Math.random() * 1000000)
    this.tf = !(this.$route.query.tf == null) ? parseInt(this.$route.query.tf) : 60;
    this.vmin = !(this.$route.query.vmin == null) ? parseInt(this.$route.query.vmin) : 0;
    this.historyLength = !(this.$route.query.hl == null) ? parseInt(this.$route.query.hl) : 1000;
    this.price_min = !(this.$route.query.pmin == null) ? parseFloat(this.$route.query.pmin) : 0;
    this.price_max = !(this.$route.query.pmax == null) ? parseFloat(this.$route.query.pmax) : 0;
    this.boundary_percent = !(this.$route.query.bp == null) ? parseInt(this.$route.query.bp) : 50;
    this.book_boundary_percent = !(this.$route.query.bbp == null) ? parseInt(this.$route.query.bbp) : 0;
    this.rd = !(this.$route.query.rd == null) ? parseInt(this.$route.query.rd) : null;
    this.timeStart = this.$route.query.time_start || Math.round((Math.round(new Date().getTime() / 1000) - (this.historyLength * this.tf * 60)) / (60 * this.tf)) * (60 * this.tf)
    this.timeEnd = this.$route.query.time_end || Math.round(((Math.round(new Date().getTime() / 1000) / (60 * this.tf)) + 1) * (60 * this.tf))
    this.tsi = !(this.$route.query.tsi == null) ? parseInt(this.$route.query.tsi) : 0;
    this.project_id = !(this.$route.query.pid == null) ? parseInt(this.$route.query.pid) : 0;
    this.reload = !(this.$route.query.reload == null) ? parseInt(this.$route.query.reload) : 0;
    this.db = 'trevax';
    if (this.$route.query.db === 'trevax_metrics' || this.$route.query.db === 'scoins')
      this.db = this.$route.query.db;

    if (!(this.$route.query.ticker == null)) {
      this.tickers = this.$route.query.ticker.split(',')
    } else {
      this.tickers.push('BINANCE_SPOT_BTC_USDT')
    }

    if (!(this.$route.query.title == null)) {
      document.title = this.$route.query.title;
    } else {
      document.title = 'CHART';
    }

    if (this.tsi === -1) {
      let httpRequest = new XMLHttpRequest();
      httpRequest.open("GET", `https://api.trevax.com/tools/markup_rand?tf=${this.tf}&pid=${this.project_id}`, false);
      httpRequest.send()
      if (httpRequest.status !== 200) {
      } else {
        let data = JSON.parse(httpRequest.response)
        this.tsi = data['tsi']
        this.tickers = [data['ticker']]
      }
      // await axios.get(`https://api.trevax.com/tools/markup_rand?tf=${this.tf}`, {})
      //     .then((response) => {
      //       this.tsi = response.data['tsi'];
      //       this.tickers = [response.data['ticker']];
      //       console.log(response.data)
      //     });
    }
    if (this.tsi > 0) {
      this.timeStart = (this.tsi - Math.round(this.historyLength / 2)) * this.tf * 60;
      this.timeEnd = (this.tsi + Math.round(this.historyLength / 2)) * this.tf * 60;
      this.NN_PARAMS = {
        'project_id': this.project_id,
        'tf': this.tf,
        'tsi': this.tsi,
        'ticker': this.tickers[0],
        'stop': 0,
        'target': 0,
        'accum_start': 0,
        'accum_end': 0,
        'direction': 0,
        'status': 1,
      }
    }
    this.tickers.forEach(ticker => {
      this.tickersStatus[ticker] = 1
    })
  },
  mounted() {
    // Chart can only be created when the component has mounted the DOM because
    // the chart needs the element with specified containerId to exist in the DOM
    //this.createDashBoard()
    this.createChart()
    if (this.reload > 0) {
      setTimeout(function () {
        window.location.reload();
      }, 1000 * this.reload * 60);
    }
  },
  beforeDestroy() {
    // "dispose" should be called when the component is unmounted to free all the resources used by the chart
    if (this.chart !== null)
      this.chart.dispose()
  }
}
</script>
