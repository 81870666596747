<template>
  <!-- Allocate chart inside div container -->
  <div style="height: 100%; width: 100%;">
    <div id="controls"
         style="height: 3%; color:Blue; font-size: 20px; text-align: center">
      <div style="display: flex; float: left">
        <a href="" id="data_prev" style="color: green"><<<</a>
      </div>
      <div style="display: flex; float: right">
        <a href="" id="data_next" style="color: green">>>></a>
      </div>
      <div id="div_timeframes"
           style="height: auto; background: black; color:Blue; font-size: 20px; text-align: center"></div>
    </div>
    <div :id="chartId" style="height: 97%"></div>
  </div>
</template>

<script>
import {
  AxisScrollStrategies,
  AxisTickStrategies,
  emptyFill,
  lightningChart,
  OHLCFigures,
  SolidLine,
  PalettedFill,
  Themes,
  LUT,
  ColorRGBA,
  emptyLine,
  synchronizeAxisIntervals,
  AutoCursorModes,
  translatePoint,
  UIOrigins,
  UILayoutBuilders,
  UIElementBuilders, SolidFill, ColorHEX, ImageFill, ImageFitMode, PointShape,
} from '@arction/lcjs'
import {get} from "https";
import axios from "axios";
import FileSaver from 'file-saver';
import chart from "@/components/Chart";


const percentile = require('stats-percentile');
const SMA = require('technicalindicators').SMA;

export default {
  name: 'SummaryDashBoard',
  // props: ['points'],
  data() {
    const license_key = typeof process.env.VUE_APP_LICENSE === 'undefined' ? '' : process.env.VUE_APP_LICENSE;
    this.lcjs = lightningChart({
      license: license_key,
      overrideInteractionMouseButtons: {
        chartXYPanMouseButton: 0,
        chartXYRectangleZoomFitMouseButton: 2,
      },
    })

    // Add the chart to the data in a way that Vue will not attach it's observers to it.
    // If the chart variable would be added in the return object, Vue would attach the observers and 
    // every time LightningChart JS made a change to any of it's internal variables, vue would try to observe the change and update.
    // Observing would slow down the chart a lot.
    this.tf = 1
    this.pid = 1
    this.tickers = []
    this.opts = {}
    this.timeStart = null
    this.dashboard = null
    this.dataCandles = {}
    this.api = 'https://api.trevax.com'
    this.tsi = 0
    this.pub = false
    this.wm = false
    this.cols = 3
    this.oldStyle = null;
    this.charts = {}
    this.price_lines = {}
    this.ask_lines = {};
    this.bid_lines = {}
    this.rate_lines = {};
    this.base_ticker = 'BINANCE_SPOT_BTC_USDT';
    this.chart_ids = ['PRICE', 'VOLUME', 'MEAN_VOLUME', 'RATE'];
    this.mean_asks = {}
    this.mean_bids = {}
    // this.volume_legend = null;
    this.tickers_ready = 0;
    this.db = 'trevax';

    return {
      chartId: null,
    }
  },
  methods: {
    convertNumbers(min, max, arr) {
      const diff = max - min;
      return arr.reduce((acc, val) => acc.concat((100 / diff) * (val - min)), []);
    },
    addKbShortcuts() {
      window.document.onkeyup = function (e) {
        if (e.code === 'KeyM' && e.shiftKey) {
          this.pub = !this.pub;
          if (this.pub) {
            window.document.getElementById('controls').style.display = 'none';
            window.document.getElementById(this.chartId).style.height = '100%';
            this.dashboard.engine.layout();
          } else {
            window.document.getElementById('controls').style.display = 'block';
            window.document.getElementById(this.chartId).style.height = '97%';
            this.dashboard.engine.layout();
          }
        } else if (e.code === 'KeyW' && e.shiftKey) {
          this.wm = !this.wm;
          if (this.wm) {
            if (null === this.oldStyle) {
              this.oldStyle = this.charts[this.tickers[0]].getSeriesBackgroundFillStyle();
            }
            for (const [ticker, chart] of Object.entries(this.charts)) {
              this.addWatermark(chart)
            }
          } else {
            for (const [ticker, chart] of Object.entries(this.charts)) {
              chart.setSeriesBackgroundFillStyle(this.oldStyle)
            }
          }
        }
      }.bind(this);
    },
    addWatermark(chart) {
      const image = new Image()
      image.crossOrigin = ''
      image.src = '/chart_logo.png'
      image.onload = () => {
        chart.setSeriesBackgroundFillStyle(
            new ImageFill({
              source: image,
              fitMode: ImageFitMode.Center,
            }),
        )
      }
    },
    addMenu() {
      let div_timeframes = document.getElementById('div_timeframes')
      div_timeframes.innerHTML = "";

      let sizes = [50, 100, 200, 500, 1000, 1500, 2000];
      {
        let container = document.createElement("span");
        let text = document.createTextNode("HL:");
        container.appendChild(text);
        container.style.color = "white";
        div_timeframes.appendChild(container);
      }

      sizes.forEach(size => {
        let href = new URL(document.URL);
        let a = document.createElement('a');
        let link = document.createTextNode(size.toString());
        a.appendChild(link);
        a.href = "#";
        let hl = 0;
        if (href.searchParams.has('hl')) {
          hl = parseInt(href.searchParams.get('hl'))
        }
        if (size === hl)
          a.style.color = 'orange'
        else
          a.style.color = '#00ff04'

        a.style.margin = '5px'
        a.id = `hl_${size}`
        a.onclick = function () {
          let hl = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('hl', hl);
          window.open(href.toString(), "_self")
          return false
        }
        div_timeframes.appendChild(a);
      });

      {
        let href = new URL(document.URL);
        let a = document.createElement('a');
        let link = document.createTextNode('SAVE');
        a.appendChild(link);
        a.href = "#";

        let bp = 0;
        if (href.searchParams.has('bp')) {
          bp = parseInt(href.searchParams.get('bp'))
        }

        a.style.color = '#f028ff'
        a.style.margin = '5px'
        a.onclick = function () {
          let ts = Math.trunc(Date.now() / 1000)
          let postfix = 'orig'
          if (this.wm) {
            postfix = 'wm'
          }
          let blob = new Blob([this.dashboard.engine.captureFrame()], {type: "image/png"});
          FileSaver.saveAs(blob, `${ts}_DB_${postfix}`);
          return false
        }.bind(this)
        div_timeframes.appendChild(a);

      }

      let href = new URL(document.URL);
      let link_prev = document.getElementById('data_prev')
      let link_next = document.getElementById('data_next')
      href.searchParams.delete('time_start')
      href.searchParams.delete('time_end')

      let ctsi = 0;
      if (href.searchParams.has('tsi')) {
        ctsi = parseInt(href.searchParams.get('tsi'));
      } else {
        ctsi = Math.round(new Date().getTime() / 1000 / this.tf / 60);
      }

      const tsi_prev = ctsi - Math.trunc(this.historyLength / 4 * 3)
      const tsi_next = ctsi + Math.trunc(this.historyLength / 4 * 3)

      href.searchParams.set('tsi', String(tsi_prev))
      link_prev.href = href

      href.searchParams.set('tsi', String(tsi_next))
      link_next.href = href

    },
    applyCandles(ticker) {
      if (ticker === 'BINANCE_SPOT_BTC_USDT') {
        let prices = [];
        this.dataCandles[ticker].forEach(candle => {
          const time = candle[0];
          const close = candle[4];
          prices.push({x: time, y: close});
        })

        this.price_lines[ticker].clear()
        this.price_lines[ticker].add(prices)
      }
      if (this.tsi > 0) {
        const tsiLine = this.charts[ticker].getDefaultAxisX().addConstantLine(false)
            .setName('tsi')
            .setValue(((this.tsi * this.tf * 60) - this.opts[ticker]['timeStart']) * 1000)
            .setStrokeStyle(new SolidLine({
              thickness: 4,
              fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(60)
            }))
            .setMouseInteractions(false)
      }
      this.loadMarkup(ticker, this.opts[ticker]['timeStart'], this.opts[ticker]['timeEnd'])
    },
    loadMarkup(ticker, time_start, time_end) {
      const tsi_start = time_start / this.tf / 60;
      const tsi_end = time_end / this.tf / 60;
      const url_markup = `${this.api}/tools/markup?ticker=${ticker}&tf=${this.tf}&tsi_start=${tsi_start}&tsi_end=${tsi_end}&project_id=${this.pid}&db=${this.db}`
      let body = ''

      get(url_markup, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              const scale = (num, in_min, in_max, out_min, out_max) => {
                return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
              }
              const data = JSON.parse(body.replace(/\]\[/g, "],["))
              let asks = [];
              let bids = [];
              let rates = [];
              let vol_arr = []
              data.forEach(markup => {
                const volume_ask = markup['volume_ask'];
                const volume_bid = markup['volume_bid'];
                vol_arr.push(volume_ask);
                vol_arr.push(volume_bid);
              })
              const max = Math.max(...vol_arr);
              const min = Math.min(...vol_arr);

              data.forEach(markup => {
                const tsi = markup['tsi'];
                const time = ((tsi * this.tf * 60) - this.opts[ticker]['timeStart']) * 1000
                const volume_ask = scale(markup['volume_ask'], min, max, 0, 100);
                const volume_bid = scale(markup['volume_bid'], min, max, 0, 100);
                const volume_rate = markup['volume_ratio']
                asks.push({x: time, y: volume_ask});
                bids.push({x: time, y: volume_bid});
                rates.push({x: time, y: volume_rate});

                if (!(time in this.mean_asks))
                  this.mean_asks[time] = [];
                this.mean_asks[time].push(volume_ask);

                if (!(time in this.mean_bids))
                  this.mean_bids[time] = [];
                this.mean_bids[time].push(volume_bid);

              })
              this.ask_lines[ticker].add(asks);
              this.bid_lines[ticker].add(bids);
              this.rate_lines[ticker].add(rates);
              this.tickers_ready++;

              const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;
              if (this.tickers.length === this.tickers_ready) {
                let asks = [];
                let bids = [];
                for (const [key, value] of Object.entries(this.mean_asks)) {
                  const time = key;
                  const avg = average(value);
                  asks.push({x: time, y: avg});
                }

                for (const [key, value] of Object.entries(this.mean_bids)) {
                  const time = key;
                  const avg = average(value);
                  bids.push({x: time, y: avg});
                }
                this.ask_lines['MEAN'].add(asks);
                this.bid_lines['MEAN'].add(bids);
              }
            }.bind(this)
        )
      }.bind(this));
    },
    loadCandles(ticker, time_start, time_end) {
      this.dataCandles[ticker] = [];
      const url_candles = `${this.api}/candles?ticker=${ticker}&tf=${this.tf}&time_start=${time_start}&time_end=${time_end}&db=${this.db}`
      let body = ''
      get(url_candles, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
              let tsArray = [];
              data.forEach(el => {
                tsArray.push(el[0])
              })
              tsArray.sort(function (a, b) {
                return a - b;
              });
              while (tsArray.length > this.historyLength) {
                tsArray.shift();
              }
              this.opts[ticker] = {}
              if (tsArray.length < this.historyLength) {
                this.opts[ticker]['timeStart'] = this.timeStart;
                this.opts[ticker]['timeEnd'] = this.timeEnd;
              } else {
                this.opts[ticker]['timeStart'] = tsArray.shift();
                this.opts[ticker]['timeEnd'] = tsArray.pop();
              }
              data.forEach(el => {
                if (tsArray.includes(el[0])) {
                  this.dataCandles[ticker].push([(el[0] - this.opts[ticker]['timeStart']) * 1000, el[1], el[2], el[3], el[4]]);
                }
              })
              this.applyCandles(ticker)
            }.bind(this)
        )
      }.bind(this));
    },
    createDashBoard() {
      this.addMenu();
      let rows = Math.trunc(this.chart_ids.length / this.cols);

      if (this.chart_ids.length > this.cols * rows)
        rows++;
      this.dashboard = lightningChart()
          .Dashboard({
            container: `${this.chartId}`,
            numberOfColumns: this.cols,
            numberOfRows: rows,
            disableAnimations: true,
            theme: Themes.darkGreen,
          })
          .setSplitterStyle(new SolidLine({thickness: 0}))

      const dashboardHeightPx = this.dashboard.engine.container.getBoundingClientRect().height
      let rowHeight = Math.trunc(dashboardHeightPx / 3)
      if (rows === 1) {
        rowHeight = dashboardHeightPx
      } else if (rows === 2) {
        rowHeight = Math.trunc(dashboardHeightPx / 2)
      } else {
        rowHeight = Math.trunc(dashboardHeightPx / 3)
      }
      this.dashboard.setHeight(rowHeight * rows)

      // for (row = 0; row < rows; row++)
      //   this.dashboard.setRowHeight(row, rowHeight)

      let col = 0;
      let row = 0;
      let xaxes = [];

      for (const chart_id of this.chart_ids) {
        this.charts[chart_id] = this.dashboard
            .createChartXY({
              columnIndex: col,
              rowIndex: row,
              defaultAxisY: {opposite: true,}
            })
            .setTitle(chart_id)
            .setTitleMarginTop(0)
            .setTitleMarginBottom(0)
            .setPadding(0)
            .setMouseInteractions(false)
            .setAutoCursor((autoCursor) => autoCursor.disposeTickMarkerX().disposeTickMarkerY().setAutoFitStrategy(undefined))
            .setAnimationsEnabled(false)

        const axisX = this.charts[chart_id]
            .getDefaultAxisX()
            .setInterval(this.timeStart * 1000, this.timeEnd * 1000)

            .setAnimationZoom(undefined)
            .setTickStrategy(AxisTickStrategies.Empty)
            // .setTickStrategy(
            //     AxisTickStrategies.DateTime,
            //     (tickStrategy) => tickStrategy.setDateOrigin(new Date(this.opts[ticker]['timeStart'] * 1000))
            // )
            .setMouseInteractions(false)
            .setScrollStrategy(AxisScrollStrategies.fitting)
            .setStrokeStyle(emptyLine)
            .setNibStyle(emptyLine)
        //     .setScrollStrategy(undefined)
        //     .setChartInteractionFitByDrag(false)
        //     .setChartInteractionZoomByDrag(false)
        //     .setChartInteractionPanByDrag(false)
        //     .setChartInteractionZoomByWheel(false)
        //     .setAxisInteractionZoomByDragging(false)
        //     .setAxisInteractionZoomByWheeling(false)
        //     .setChartInteractions(false)
        const axisY = this.charts[chart_id]
            .getDefaultAxisY()
            .setTickStrategy(AxisTickStrategies.Empty)
            .setMouseInteractions(false)
            .setAnimationZoom(undefined)
            .setStrokeStyle(emptyLine)
            .setNibStyle(emptyLine)
        // .setAxisInteractionZoomByDragging(false)
        // .setAxisInteractionZoomByWheeling(false)
        // .setChartInteractions(false)
        xaxes.push(axisX)
        col++;
        if (col === this.cols) {
          col = 0;
          row++;
        }
      }

      // this.volume_legend = this.charts['VOLUME'].addLegendBox()

      this.price_lines[this.base_ticker] = this.charts['PRICE'].addLineSeries();
      this.price_lines[this.base_ticker].setStrokeStyle(new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({color: ColorHEX('#ffffff')})
      }))

      this.ask_lines['MEAN'] = this.charts['MEAN_VOLUME'].addLineSeries();
      this.ask_lines['MEAN'].setStrokeStyle(new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({color: ColorHEX('#ff1c1c')})
      }))

      this.bid_lines['MEAN'] = this.charts['MEAN_VOLUME'].addLineSeries();
      this.bid_lines['MEAN'].setStrokeStyle(new SolidLine({
        thickness: 1,
        fillStyle: new SolidFill({color: ColorHEX('#94ff08')})
      }))

      for (const ticker of this.tickers) {
        this.ask_lines[ticker] = this.charts['VOLUME'].addPointSeries({
          pointShape: PointShape.Circle,
        });
        this.ask_lines[ticker].setPointFillStyle(new SolidFill({color: ColorHEX('#ff1c1c')}))
        this.ask_lines[ticker].setName(`${ticker}_ASK`)
            .setPointSize(3)

        this.bid_lines[ticker] = this.charts['VOLUME'].addPointSeries({
          pointShape: PointShape.Circle,
        });
        this.bid_lines[ticker].setPointFillStyle(new SolidFill({color: ColorHEX('#94ff08')}))
        this.bid_lines[ticker].setName(`${ticker}_BID`).setPointSize(3)


        this.rate_lines[ticker] = this.charts['RATE'].addPointSeries({
          pointShape: PointShape.Circle,
        });
        this.rate_lines[ticker].setPointFillStyle(new SolidFill({color: ColorHEX('#e3fc40')}))
        this.loadCandles(ticker, this.timeStart, this.timeEnd)
        // this.volume_legend.add(this.ask_lines[ticker])
        // this.volume_legend.add(this.bid_lines[ticker])

      }
      synchronizeAxisIntervals(...xaxes)
      const xTicks = xaxes.map((axisx) => axisx.addCustomTick().dispose())

      this.addKbShortcuts();

      Object.values(this.charts).forEach((chart) => {
        // chart.setAutoCursorMode(AutoCursorModes.disabled)
        chart.onSeriesBackgroundMouseMove((_, event) => {
          const mouseLocationEngine = chart.engine.clientLocation2Engine(event.clientX, event.clientY)
          const mouseLocationAxisX = translatePoint(mouseLocationEngine, chart.engine.scale, {
            x: chart.getDefaultAxisX(),
            y: chart.getDefaultAxisY()
          }).x
          xTicks.forEach((xTick) => xTick
              .restore()
              .setValue(mouseLocationAxisX)
          )
        })

        if (this.tsi > 0) {
          const tsiLine = chart.getDefaultAxisX().addConstantLine(false)
              .setName('tsi')
              .setValue(this.tsi * this.tf * 60 * 1000)
              .setStrokeStyle(new SolidLine({
                thickness: 4,
                fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(60)
              }))
              .setMouseInteractions(false)
        }
      })
    },
  },

  async beforeMount() {
    // Generate random ID to us as the containerId for the chart and the target div id
    this.chartId = Math.trunc(Math.random() * 1000000)
    this.tf = !(this.$route.query.tf == null) ? parseInt(this.$route.query.tf) : 1;
    this.historyLength = !(this.$route.query.hl == null) ? parseInt(this.$route.query.hl) : 100;
    const currentTSI = Math.round(new Date().getTime() / 1000 / this.tf / 60);
    this.timeStart = this.$route.query.time_start || (currentTSI - this.historyLength) * this.tf * 60;
    this.timeEnd = this.$route.query.time_end || (currentTSI + 1) * this.tf * 60;
    this.tsi = !(this.$route.query.tsi == null) ? parseInt(this.$route.query.tsi) : 0;
    this.pid = !(this.$route.query.pid == null) ? parseInt(this.$route.query.pid) : 0;
    this.cols = !(this.$route.query.cols == null) ? parseInt(this.$route.query.cols) : 1;
    if (!(this.$route.query.tickers == null)) {
      this.tickers = this.$route.query.tickers.split(',')
    }

    if (this.tsi > 0) {
      this.timeStart = (this.tsi - Math.round(this.historyLength / 2) + 2) * this.tf * 60;
      this.timeEnd = (this.tsi + Math.round(this.historyLength / 2)) * this.tf * 60;
    }
    this.db = 'trevax';
    if (this.$route.query.db === 'trevax_metrics' || this.$route.query.db === 'scoins')
      this.db = this.$route.query.db;
  },
  mounted() {
    // Chart can only be created when the component has mounted the DOM because
    // the chart needs the element with specified containerId to exist in the DOM
    // this.createChart()
    (async () => {
      if (this.tickers.length === 0) {
        this.tickers = (await axios.get(`${this.api}/tools/tickers_summary_db&db=${this.db}`)).data;
      }
      let tickers_cnt = this.tickers.length;
      if (tickers_cnt < this.cols) {
        this.cols = tickers_cnt;
      }
      this.createDashBoard();
    })();
    // setTimeout(function () {
    //   window.location.reload();
    // }, 1000 * 60 * 5);
  },
  beforeDestroy() {
    // "dispose" should be called when the component is unmounted to free all the resources used by the chart
    if (this.chart !== null)
      this.dashboard.dispose()
  }
}
</script>
