<template>
  <!-- Allocate chart inside div container -->
  <div style="height: 100%; width: 100%;">
    <div id="controls"
         style="height: 3%; color:Blue; font-size: 20px; text-align: center">
      <div style="display: flex; float: left">
        <a href="" id="data_prev" style="color: green"><<<</a>
      </div>
      <div style="display: flex; float: right">
        <a href="" id="data_next" style="color: green">>>></a>
      </div>
      <div id="div_timeframes"
           style="height: auto; background: black; color:Blue; font-size: 20px; text-align: center"></div>
    </div>
    <div :id="chartId" style="height: 97%"></div>
  </div>
</template>

<script>
import {
  AxisScrollStrategies,
  AxisTickStrategies,
  ColorHEX,
  ColorRGBA,
  emptyLine,
  translatePoint,
  lightningChart,
  LUT,
  OHLCFigures,
  PalettedFill,
  SolidFill,
  SolidLine,
  synchronizeAxisIntervals,
  Themes,
} from '@arction/lcjs'
import {get} from "https";
import FileSaver from 'file-saver';

const request = require('request');
const percentile = require('stats-percentile');

function get_page(url) {
  return new Promise((resolve, reject) => {
    request(url, (error, response, body) => {
      if (error) reject(error);
      if (response.statusCode !== 200) {
        reject('Invalid status code <' + response.statusCode + '>');
      }
      resolve(body);
    });
  });
}

export default {
  name: 'DashBoardCombo',
  // props: ['points'],
  data() {
    const license_key = typeof process.env.VUE_APP_LICENSE === 'undefined' ? '' : process.env.VUE_APP_LICENSE;
    this.lcjs = lightningChart({
      license: license_key,
      overrideInteractionMouseButtons: {
        chartXYPanMouseButton: 0,
        chartXYRectangleZoomFitMouseButton: 2,
      },
    })

    // Add the chart to the data in a way that Vue will not attach it's observers to it.
    // If the chart variable would be added in the return object, Vue would attach the observers and 
    // every time LightningChart JS made a change to any of it's internal variables, vue would try to observe the change and update.
    // Observing would slow down the chart a lot.
    this.tf = 60
    this.tickers = []
    this.charts = {}
    this.candles = {}
    this.heatmaps = {}
    this.opts = {}
    this.time_start = null
    this.time_end = null
    this.dashboard = null
    this.dataBooks = {}
    this.dataTrades = {}
    this.dataCandles = {}
    this.api = 'https://api.trevax.com'
    this.boundary_percent = 10
    this.levels = 100
    this.endX = 0
    this.yMin = 0
    this.yMax = 0
    this.tsi = 0
    this.base = null
    this.reload = 0;
    this.db = 0;
    this.vmin = 0;
    this.vmax = 0;
    this.leverages = [];
    this.xaxes = [];
    // this.yaxes = {};
    this.tsi_start = 0;
    this.tsi_end = 0;
    this.cols = 3;
    this.resample_config = {}

    return {
      chartId: null,
    }
  },
  methods: {
    addMenu() {
      let div_timeframes = document.getElementById('div_timeframes')
      div_timeframes.innerHTML = "";

      {
        let container = document.createElement("span");
        let text = document.createTextNode("TF:");
        container.appendChild(text);
        container.style.color = "white";
        div_timeframes.appendChild(container);
      }

      [1, 5, 10, 15, 30, 60, 120, 180, 240, 720, 1440].forEach(tf => {
        let a = document.createElement('a');
        let link = document.createTextNode(tf.toString());
        a.appendChild(link);
        a.href = "#";
        if (this.tf === tf)
          a.style.color = 'orange'
        else
          a.style.color = 'blue'
        a.style.margin = '5px'
        a.id = `TF_${tf}`
        a.onclick = function () {
          let tf = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('tf', tf);
          window.open(href.toString(), "_self")
          return false
        }
        div_timeframes.appendChild(a);
      });


      let sizes = [50, 100, 200, 500, 700, 1000];

      {
        let container = document.createElement("span");
        let text = document.createTextNode("HL:");
        container.appendChild(text);
        container.style.color = "white";
        div_timeframes.appendChild(container);
      }

      sizes.forEach(size => {
        let href = new URL(document.URL);
        let a = document.createElement('a');
        let link = document.createTextNode(size.toString());
        a.appendChild(link);
        a.href = "#";
        let hl = 0;
        if (href.searchParams.has('hl')) {
          hl = parseInt(href.searchParams.get('hl'))
        }
        if (size === hl)
          a.style.color = 'orange'
        else
          a.style.color = '#00ff04'

        a.style.margin = '5px'
        a.id = `hl_${size}`
        a.onclick = function () {
          let hl = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('hl', hl);
          window.open(href.toString(), "_self")
          return false
        }
        div_timeframes.appendChild(a);
      });

      {
        let container = document.createElement("span");
        let text = document.createTextNode("LEVELS:");
        container.appendChild(text);
        container.style.color = "white";
        div_timeframes.appendChild(container);
      }
      sizes.forEach(size => {
        let href = new URL(document.URL);
        let a = document.createElement('a');
        let link = document.createTextNode(size.toString());
        a.appendChild(link);
        a.href = "#";
        let levels = 0;
        if (href.searchParams.has('levels')) {
          levels = parseInt(href.searchParams.get('levels'))
        }
        if (size === levels)
          a.style.color = 'orange'
        else
          a.style.color = '#fff600'

        a.style.margin = '5px'
        a.id = `levels_${size}`
        a.onclick = function () {
          let levels = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('levels', levels);
          window.open(href.toString(), "_self")
          return false
        }
        div_timeframes.appendChild(a);
      });

      {
        let container = document.createElement("span");
        let text = document.createTextNode("BP:");
        container.appendChild(text);
        container.style.color = "white";
        div_timeframes.appendChild(container);
      }
      [1, 3, 5, 10, 15, 25, 30, 40, 50].forEach(prc => {
        let href = new URL(document.URL);
        let a = document.createElement('a');
        let link = document.createTextNode(prc.toString());
        a.appendChild(link);
        a.href = "#";

        let bp = 0;
        if (href.searchParams.has('bp')) {
          bp = parseInt(href.searchParams.get('bp'))
        }
        if (prc === bp)
          a.style.color = 'orange'
        else
          a.style.color = '#f028ff'
        a.style.margin = '5px'
        a.id = `bp_${prc}`
        a.onclick = function () {
          let bp = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('bp', bp);
          window.open(href.toString(), "_self")
          return false
        }
        div_timeframes.appendChild(a);
      });


      {
        let href = new URL(document.URL);
        let a = document.createElement('a');
        let link = document.createTextNode('SAVE');
        a.appendChild(link);
        a.href = "#";

        let bp = 0;
        if (href.searchParams.has('bp')) {
          bp = parseInt(href.searchParams.get('bp'))
        }

        a.style.color = '#f028ff'
        a.style.margin = '5px'
        a.onclick = function () {
          let ts = Math.trunc(Date.now() / 1000)
          let postfix = 'orig'
          if (this.wm) {
            postfix = 'wm'
          }
          let blob = new Blob([this.dashboard.engine.captureFrame()], {type: "image/png"});
          FileSaver.saveAs(blob, `${ts}_DB_${postfix}`);
          return false
        }.bind(this)
        div_timeframes.appendChild(a);

      }

      let href = new URL(document.URL);
      let link_prev = document.getElementById('data_prev')
      let link_next = document.getElementById('data_next')
      href.searchParams.delete('time_start')
      href.searchParams.delete('time_end')

      let ctsi = 0;
      if (href.searchParams.has('tsi')) {
        ctsi = parseInt(href.searchParams.get('tsi'));
      } else {
        ctsi = this.tsi_end;
      }

      const tsi_prev = ctsi - this.historyLength + 1
      const tsi_next = ctsi + this.historyLength - 1

      href.searchParams.set('tsi', String(tsi_prev))
      link_prev.href = href

      href.searchParams.set('tsi', String(tsi_next))
      link_next.href = href

    },
    dataPointToTSI(x) {
      return Math.trunc(x / 1000 / 60 / this.tf)
    },
    tsiToDateTime(tsi) {
      let date = new Date(tsi * this.tf * 60 * 1000)
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    },
    loadCandles(ticker) {
      this.dataCandles[ticker] = [];
      const url_candles = `${this.api}/data/candles/${this.db}/${ticker}/${this.tf}/${this.tsi_start}/${this.tsi_end}`;
      // const url_candles = `${this.api}/candles?ticker=${ticker}&tf=${this.tf}&tsi_start=${this.tsi_start}&tsi_end=${this.tsi_end}&db=${this.db}`
      let body = ''
      get(url_candles, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
              let tsArray = [];
              data.forEach(el => {
                tsArray.push(el[0])
              })
              tsArray.sort(function (a, b) {
                return a - b;
              });
              while (tsArray.length > this.historyLength) {
                tsArray.shift();
              }
              data.forEach(el => {
                // if (tsArray.includes(el[0])) {
                {
                  let tsi = el[0];
                  let open = el[1];
                  let high = el[2];
                  let low = el[3];
                  let close = el[4];
                  const cut_level = 8;

                  if (open > close) { // DOWN
                    if ((high - open) / (open - close) >= cut_level)
                      high = open;
                    if ((close - low) / (open - close) >= cut_level)
                      low = close;
                  } else if (open < close) { // UP
                    if ((high - close) / (close - open) >= cut_level)
                      high = close;
                    if ((open - low) / (close - open) >= cut_level)
                      low = open;
                  } else {
                    high = close;
                    low = close;
                  }
                  const candle_time = tsi * this.tf * 60 * 1000;
                  // console.log(tsi, candle_time, (candle_time - time_start) * 1000);
                  // this.dataCandles[ticker].push([(candle_time - time_start) * 1000, open, high, low, close]);
                  this.dataCandles[ticker].push([candle_time, open, high, low, close]);
                }
              })
              this.applyCandles(ticker)
            }.bind(this)
        )
      }.bind(this));
    },
    applyCandles(ticker) {
      let price_min = 0.0;
      let price_max = 0.0;
      let yMin = 0.0;
      let yMax = 0.0;
      this.dataCandles[ticker].forEach(candle => {
        const high = candle[2]
        const low = candle[3]

        if (yMin === 0 || yMin > low)
          yMin = low
        if (yMax < high)
          yMax = high
      })

      for (const chart_id of [`${ticker}_BOOK`, `${ticker}_LIQ`]) {
        // for (const chart_id of [`${ticker}_BOOK`]) {
        this.candles[chart_id].clear()
        this.candles[chart_id].add(this.dataCandles[ticker])
        if (this.boundary_percent > 0) {
          const book_boundary = yMax / 100 * this.boundary_percent
          price_max = yMax + book_boundary
          price_min = yMin - book_boundary
          price_min < 0 ? 0 : price_min
        }

        this.opts[ticker]['price_min'] = price_min;
        this.opts[ticker]['price_max'] = price_max;

        (async () => {
          let axisY = this.charts[chart_id].getDefaultAxisY()
          axisY.setInterval(price_min, price_max, false, true)

          // let axisX = this.charts[chart_id].getDefaultAxisX()
          // axisX.setInterval(this.tsi_start * this.tf * 60 * 1000, this.tsi_end * this.tf * 60 * 1000)
        })();

        // if (this.tsi > 0) {
        //   const tsiLine = this.charts[chart_id].getDefaultAxisX().addConstantLine(false)
        //       .setName('tsi')
        //       .setValue(((this.tsi * this.tf * 60) - this.time_start) * 1000)
        //       .setStrokeStyle(new SolidLine({
        //         thickness: 4,
        //         fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(60)
        //       }))
        //       .setMouseInteractions(false)
        // }
      }
      this.loadTrades(ticker)
      this.loadBook(ticker)
    },

    loadTrades(ticker) {
      const resample = this.resample_config[ticker] ?? 0;
      // /trades/:db/:ticker/:tf/:tsi_start/:tsi_end/:resample?
      const url_trades = `${this.api}/data/trades/${this.db}/${ticker}/${this.tf}/${this.tsi_start}/${this.tsi_end}/${resample}`;
      // const url_trades = `${this.api}/data/hmlevels?ticker=${ticker}&tf=${this.tf}&tsi_start=${this.tsi_start}&tsi_end=${this.tsi_end}&pmin=${this.opts[ticker]['price_min']}&pmax=${this.opts[ticker]['price_max']}&levels=${this.levels}&db=${this.db}&unscaled=1`
      let body = ''
      get(url_trades, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              let rows = this.tsi_end - this.tsi_start;
              // let heatmap = Array.from({length: rows}, () => Array(this.levels).fill(0.0));
              // let heatmap = {};
              let heatmap = Array.from({length: rows});
              for (let heatmap_x_index = 0; heatmap_x_index < rows; heatmap_x_index++) {
                heatmap[heatmap_x_index] = Array(this.levels).fill(0.0);
              }

              const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
              data.forEach(el => {
                const tsi = parseInt(el[0]);
                const price = parseFloat(el[1]);
                const vb = parseFloat(el[2]) * price;
                const vs = parseFloat(el[3]) * price;
                const heatmap_x_index = tsi - this.tsi_start;

                for (let leverage of this.leverages) {
                  let buy_liq_price = price * (1 - 1 / leverage)
                  let sell_liq_price = price * (1 + 1 / leverage)

                  const buy_liq_price_scaled = Math.floor((buy_liq_price - this.opts[ticker]['price_min']) * (this.levels / (this.opts[ticker]['price_max'] - this.opts[ticker]['price_min'])));
                  const sell_liq_price_scaled = Math.floor((sell_liq_price - this.opts[ticker]['price_min']) * (this.levels / (this.opts[ticker]['price_max'] - this.opts[ticker]['price_min'])));

                  try {
                    if (buy_liq_price >= this.opts[ticker]['price_min'] && buy_liq_price <= this.opts[ticker]['price_max']) {
                      if (!(buy_liq_price_scaled in heatmap[heatmap_x_index]))
                        heatmap[heatmap_x_index][buy_liq_price_scaled] = vb;
                      else
                        heatmap[heatmap_x_index][buy_liq_price_scaled] += vb;
                    }
                    // console.log(heatmap_x_index, buy_liq_price_scaled, vb, heatmap[heatmap_x_index][buy_liq_price_scaled]);
                  } catch (e) {
                  }
                  try {
                    if (sell_liq_price >= this.opts[ticker]['price_min'] && sell_liq_price <= this.opts[ticker]['price_max']) {
                      if (!(sell_liq_price_scaled in heatmap[heatmap_x_index]))
                        heatmap[heatmap_x_index][sell_liq_price_scaled] = vs;
                      else
                        heatmap[heatmap_x_index][sell_liq_price_scaled] += vs;
                    }
                  } catch (e) {
                  }
                }
              })

              for (let price = 0; price < this.levels; price++) {
                for (let heatmap_x_index = 0; heatmap_x_index < rows; heatmap_x_index++) {
                  let current_value = 0.0;
                  let prev_value = 0.0;
                  current_value = heatmap[heatmap_x_index][price];
                  try {
                    prev_value = heatmap[heatmap_x_index - 1][price];
                  } catch (e) {
                    prev_value = 0.0;
                  }

                  if (heatmap_x_index > 0) {
                    heatmap[heatmap_x_index][price] = current_value + prev_value;
                  } else
                    heatmap[heatmap_x_index][price] = current_value;

                  try {
                    const candle = this.dataCandles[ticker][heatmap_x_index];
                    const high = Math.floor((parseFloat(candle[2]) - this.opts[ticker]['price_min']) * (this.levels / (this.opts[ticker]['price_max'] - this.opts[ticker]['price_min'])));
                    const low = Math.floor((parseFloat(candle[3]) - this.opts[ticker]['price_min']) * (this.levels / (this.opts[ticker]['price_max'] - this.opts[ticker]['price_min'])));
                    if (price >= low && price <= high)
                      heatmap[heatmap_x_index][price] = 0.0;
                  } catch (e) {
                  }
                }
              }
              this.dataTrades[ticker] = heatmap;
              this.applyTrades(ticker);
            }.bind(this)
        )
      }.bind(this));
    },
    applyTrades(ticker) {
      let vol_all = []
      let max_vol = 0
      let new_book = Array(this.historyLength).fill(0.0).map(() => Array(this.levels))
      let books = this.dataTrades[ticker];
      for (const [ts, data] of Object.entries(books)) {
        for (const [price, volume] of Object.entries(data)) {
          if (price >= this.levels || ts >= this.historyLength) {
            continue;
          }
          new_book[ts][price] = volume;
          if (volume > max_vol)
            max_vol = volume
          vol_all.push(volume)
        }
      }

      const p_low = percentile(vol_all, 75)
      const p_high = percentile(vol_all, 99)
      const p_med = ((p_high - p_low) / 2) + p_low

      const xStep = this.tf * 60 * 1000

      // HEATMAP
      const chart = this.charts[`${ticker}_LIQ`];
      this.heatmaps[`${ticker}_LIQ`] = chart.addHeatmapGridSeries(
          {
            columns: this.historyLength,
            rows: this.levels,
            dataOrder: 'columns',
            start: {x: this.tsi_start * xStep, y: this.opts[ticker]['price_min']},
            end: {x: this.tsi_end * xStep, y: this.opts[ticker]['price_max']},
            step: {x: xStep, y: (this.opts[ticker]['price_max'] - this.opts[ticker]['price_min']) / this.levels}
          }
      ).setWireframeStyle(emptyLine);

      this.heatmaps[`${ticker}_LIQ`].setIntensityInterpolation('disabled')
          // this.heatmaps[`${ticker}_LIQ`].setIntensityInterpolation('bilinear')
          .setFillStyle(new PalettedFill({
            lookUpProperty: 'value',
            lut: new LUT({
              interpolate: true,
              // steps: [
              //   {value: 0, color: ColorRGBA(0, 0, 0, 0)},
              //   {value: p_med / 2, color: ColorRGBA(252, 221, 0, 64)},
              //   {value: p_med, color: ColorRGBA(252, 221, 0, 128)},
              //   {value: p_high, color: ColorRGBA(250, 0, 0, 200)},
              // ]
              steps: [
                {value: 0, color: ColorRGBA(0, 0, 0, 0)},
                // {value: p_med / 2, color: ColorRGBA(252, 221, 0, 100)},
                {value: p_med, color: ColorRGBA(200, 0, 0, 20)},
                {value: p_high, color: ColorRGBA(255, 0, 0, 250)},
              ]
            })
          }))
          // .setWireframeStyle(emptyLine)
          .setCursorResultTableFormatter((builder, series, dataPoint) => builder
              .addRow('V', '', dataPoint.intensity.toFixed(1))
              // .addRow('Time:', '', chart.getDefaultAxisX().formatValue(dataPoint.x))
              .addRow('Price:', '', chart.getDefaultAxisY().formatValue(dataPoint.y))
              .addRow('TSI:', '', String(this.dataPointToTSI(dataPoint.x)))
              .addRow('TS:', '', String(this.tsiToDateTime(this.dataPointToTSI(dataPoint.x))))
          )
      this.heatmaps[`${ticker}_LIQ`].invalidateIntensityValues(new_book)
    },

    loadBook(ticker) {
      // /book/:db/:ticker/:tf/:tsi_start/:tsi_end/:pmin/:pmax/:levels
      const url_book = `${this.api}/data/book/${this.db}/${ticker}/${this.tf}/${this.tsi_start}/${this.tsi_end}/${this.opts[ticker]['price_min']}/${this.opts[ticker]['price_max']}/${this.levels}`;
      // const url_book = `${this.api}/book/hmlevels?ticker=${ticker}&tf=${this.tf}&tsi_start=${this.tsi_start}&tsi_end=${this.tsi_end}&pmin=${this.opts[ticker]['price_min']}&pmax=${this.opts[ticker]['price_max']}&levels=${this.levels}&db=${this.db}`
      let body = ''
      get(url_book, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              let books = {};
              const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
              data.forEach(el => {
                const tsi = el[0];
                const price_scaled = el[1];
                const price_unscaled = el[2];
                const volume = el[3];
                if (!(tsi in books))
                  books[tsi] = {};
                if (!(price_scaled in books[tsi]))
                  books[tsi][price_scaled] = volume;
                else
                  books[tsi][price_scaled] += volume;
              })
              this.dataBooks[ticker] = books;
              this.applyBook(ticker);
            }.bind(this)
        )
      }.bind(this));
    },
    applyBook(ticker) {
      let vol_all = []
      let max_vol = 0
      let new_book = Array(this.historyLength).fill(0.0).map(() => Array(this.levels))
      for (let heatmap_x_index = 0; heatmap_x_index < this.levels; heatmap_x_index++) {
        new_book[heatmap_x_index] = Array(this.levels).fill(0.0);
      }
      let books = this.dataBooks[ticker];
      for (const [tsi, data] of Object.entries(books)) {
        const int_tsi = parseInt(tsi, 10);
        const heatmap_x_index = int_tsi - this.tsi_start;
        for (const [price_idx, volume] of Object.entries(data)) {
          const int_price_idx = parseFloat(price_idx);
          const int_volume = parseInt(volume, 10);
          if (int_price_idx >= this.levels || int_price_idx < 0 || heatmap_x_index >= this.historyLength || heatmap_x_index < 0) {
            continue;
          }
          new_book[heatmap_x_index][int_price_idx] = int_volume;
          if (int_volume > max_vol)
            max_vol = int_volume
          vol_all.push(int_volume)
        }
      }

      const p_low = percentile(vol_all, 75)
      const p_high = percentile(vol_all, 99)
      const p_med = ((p_high - p_low) / 2) + p_low

      const xStep = this.tf * 60 * 1000

      // HEATMAP
      const chart = this.charts[`${ticker}_BOOK`];
      this.heatmaps[`${ticker}_BOOK`] = chart.addHeatmapGridSeries(
          {
            columns: this.historyLength,
            rows: this.levels,
            dataOrder: 'columns',
            start: {x: this.tsi_start * xStep, y: this.opts[ticker]['price_min']},
            end: {x: this.tsi_end * xStep, y: this.opts[ticker]['price_max']},
            step: {x: xStep, y: (this.opts[ticker]['price_max'] - this.opts[ticker]['price_min']) / this.levels}
          }
      ).setWireframeStyle(emptyLine);

      // // this.heatmaps[`${ticker}_BOOK`].setIntensityInterpolation('disabled')
      this.heatmaps[`${ticker}_BOOK`].setIntensityInterpolation('bilinear')
          .setFillStyle(new PalettedFill({
            lookUpProperty: 'value',
            lut: new LUT({
              interpolate: true,
              // steps: [
              //   {value: 0, color: ColorRGBA(0, 0, 0, 8)},
              //   {value: p_med / 2, color: ColorRGBA(252, 221, 0, 64)},
              //   {value: p_med, color: ColorRGBA(252, 221, 0, 128)},
              //   {value: p_high, color: ColorRGBA(250, 0, 0, 200)},
              // ]
              steps: [
                {value: 0, color: ColorRGBA(0, 0, 0, 0)},
                // {value: p_med / 2, color: ColorRGBA(100, 100, 100, 100)},
                // {value: p_med, color: ColorRGBA(200, 200, 200, 200)},
                {value: p_high, color: ColorRGBA(255, 255, 0, 180)},
              ]

            })
          }))
          // .setWireframeStyle(emptyLine)
          .setCursorResultTableFormatter((builder, series, dataPoint) => builder
              .addRow('V', '', dataPoint.intensity.toFixed(1))
              // .addRow('Time:', '', chart.getDefaultAxisX().formatValue(dataPoint.x))
              .addRow('Price:', '', chart.getDefaultAxisY().formatValue(dataPoint.y))
              .addRow('TSI:', '', String(this.dataPointToTSI(dataPoint.x)))
              .addRow('TS:', '', String(this.tsiToDateTime(this.dataPointToTSI(dataPoint.x))))
          )
      this.heatmaps[`${ticker}_BOOK`].invalidateIntensityValues(new_book)
    },
    createDashBoard() {
      this.addMenu();
      let rows = Math.trunc(this.tickers.length / this.cols);

      if (this.tickers.length > this.cols * rows)
        rows++;
      this.dashboard = lightningChart()
          .Dashboard({
            container: `${this.chartId}`,
            numberOfColumns: this.cols,
            numberOfRows: rows,
            disableAnimations: true,
            theme: Themes.darkGreen,
          })
          .setSplitterStyle(new SolidLine({thickness: 2}))

      const dashboardHeightPx = this.dashboard.engine.container.getBoundingClientRect().height

      let rowHeight = Math.trunc(dashboardHeightPx / 3)

      if (rows === 1) {
        rowHeight = dashboardHeightPx
      } else if (rows === 2) {
        rowHeight = Math.trunc(dashboardHeightPx / 2)
      } else {
        rowHeight = Math.trunc(dashboardHeightPx / 3)
      }
      this.dashboard.setHeight(rowHeight * rows)
      for (let row = 0; row < rows; row++) {
        this.dashboard.setRowHeight(row, rowHeight)
      }

      this.charts = {};
      this.candles = {};

      let row = 0;
      let col = 0;
      for (const ticker_info of this.tickers) {
        let ticker;
        if (typeof ticker_info === 'string' || ticker_info instanceof String) {
          ticker = ticker_info;
        } else {
          ticker = ticker_info['ticker']
        }

        this.opts[ticker] = {}
        // this.yaxes[ticker] = [];

        for (const chart_id of [`${ticker}_BOOK`, `${ticker}_LIQ`]) {
          this.charts[chart_id] = this.dashboard
              .createChartXY({
                columnIndex: col,
                rowIndex: row,
                defaultAxisY: {opposite: true,}
              })
              .setTitle(ticker)
              .setTitleMarginTop(0)
              .setTitleMarginBottom(0)
              .setPadding(0)
              .setMouseInteractions(false)
              .setAutoCursor((autoCursor) => autoCursor.disposeTickMarkerX().disposeTickMarkerY().setAutoFitStrategy(undefined))
              .setAnimationsEnabled(false)

          const axisX = this.charts[chart_id]
              .getDefaultAxisX()
              .setAnimationZoom(undefined)
              .setTickStrategy(AxisTickStrategies.Empty)
              // .setTickStrategy(
              //     AxisTickStrategies.DateTime,
              //     (tickStrategy) => tickStrategy.setDateOrigin(new Date(this.time_start))
              // )
              .setMouseInteractions(false)
              .setScrollStrategy(AxisScrollStrategies.fitting)
              .setStrokeStyle(emptyLine)
              .setNibStyle(emptyLine)
          const axisY = this.charts[chart_id]
              .getDefaultAxisY()
              .setTickStrategy(AxisTickStrategies.Empty)
              .setMouseInteractions(false)
              .setAnimationZoom(undefined)
              .setStrokeStyle(emptyLine)
              .setNibStyle(emptyLine)
          this.xaxes.push(axisX)
          // this.yaxes[ticker].push(axisY)
          this.candles[chart_id] = this.charts[chart_id].addOHLCSeries({positiveFigure: OHLCFigures.Candlestick}).setFigureAutoFitting(false);
        }
        col++;
        if (col === this.cols) {
          col = 0;
          row++;
        }
        this.loadCandles(ticker)
      }

      synchronizeAxisIntervals(...this.xaxes)
      // synchronizeAxisIntervals(...this.yaxes[ticker])
      const xTicks = this.xaxes.map((axisx) => axisx.addCustomTick().dispose().setAllocatesAxisSpace(false))
      // const yTicks = this.yaxes[ticker].map((axisy) => axisy.addCustomTick().dispose().setAllocatesAxisSpace(false))

      Object.values(this.charts).forEach((chart) => {
        chart.onSeriesBackgroundMouseMove((_, event) => {
          const mouseLocationEngine = chart.engine.clientLocation2Engine(event.clientX, event.clientY)
          const chartPoint = translatePoint(mouseLocationEngine, chart.engine.scale, {
            x: chart.getDefaultAxisX(),
            y: chart.getDefaultAxisY()
          });
          xTicks.forEach((xTick) => xTick
              .restore()
              .setValue(chartPoint.x)
          )
          // yTicks.forEach((yTick) => yTick
          //     .restore()
          //     .setValue(chartPoint.y)
          // )
        })

        if (this.tsi > 0) {
          const tsiLine = chart.getDefaultAxisX().addConstantLine(false)
              .setName('tsi')
              .setValue(this.tsi * this.tf * 60 * 1000)
              .setStrokeStyle(new SolidLine({
                thickness: 4,
                fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(60)
              }))
              .setMouseInteractions(false)
        }
      })
    },
    async beforeMnt() {
      // Generate random ID to us as the containerId for the chart and the target div id
      this.chartId = Math.trunc(Math.random() * 1000000)
      this.tf = !(this.$route.query.tf == null) ? parseInt(this.$route.query.tf) : 60;
      this.historyLength = !(this.$route.query.hl == null) ? parseInt(this.$route.query.hl) : 100;
      this.boundary_percent = !(this.$route.query.bp == null) ? parseInt(this.$route.query.bp) : 10;
      this.levels = !(this.$route.query.levels == null) ? parseInt(this.$route.query.levels) : 100;
      const currentTSI = Math.round(new Date().getTime() / 1000 / this.tf / 60);
      this.time_start = this.$route.query.time_start || (currentTSI - this.historyLength) * this.tf * 60;
      this.time_end = this.$route.query.time_end || currentTSI * this.tf * 60;
      this.tsi = !(this.$route.query.tsi == null) ? parseInt(this.$route.query.tsi) : 0;
      this.base = !(this.$route.query.base == null) ? this.$route.query.base : null;
      this.reload = !(this.$route.query.reload == null) ? parseInt(this.$route.query.reload) : 0;
      this.cols = !(this.$route.query.cols == null) ? parseInt(this.$route.query.cols) : 3;

      this.db = 'trevax';
      if (this.$route.query.db === 'trevax_metrics' || this.$route.query.db === 'scoins')
        this.db = this.$route.query.db;
      this.vmax = !(this.$route.query.vmax == null) ? parseInt(this.$route.query.vmax) : 0;
      this.vmin = !(this.$route.query.vmin == null) ? parseInt(this.$route.query.vmin) : 0;


      if (!(this.$route.query.title == null)) {
        document.title = this.$route.query.title;
      } else {
        document.title = 'DB';
      }
      if (!(this.$route.query.tickers == null)) {
        this.tickers = this.$route.query.tickers.split(',')
      } else {
        this.tickers = [];
      }


      if (!(this.$route.query.leverages == null)) {
        this.leverages = this.$route.query.leverages.split(',').map(i => parseInt(i));
      } else {
        this.leverages = [25, 50, 100];
      }


      if (!(this.$route.query.products == null)) {
        let products = this.$route.query.products.split(',');
        for (const product of products) {
          const url_tickers = `${this.api}/tools/get_tickers_by_product?product=${product}&db=${this.db}`;
          let tmp_tickers = JSON.parse(await get_page(url_tickers));
          if (tmp_tickers.length > 0) {
            this.tickers.push(...tmp_tickers);
          }
        }
      }

      if (this.tsi > 0) {
        this.tsi_end = this.tsi;
        this.tsi_start = this.tsi_end - this.historyLength;
        this.time_start = (this.tsi - Math.round(this.historyLength / 2) + 2) * this.tf * 60;
        this.time_end = (this.tsi + Math.round(this.historyLength / 2)) * this.tf * 60;
      } else {
        this.tsi_start = Math.trunc(this.time_start / this.tf / 60);
        this.tsi_end = Math.trunc(this.time_end / this.tf / 60);
      }

      try {
        const response = await fetch('/resample.json');
        if (response.ok) {
          this.resample_config = await response.json();
        } else {
          this.resample_config = {}; // Если файл не найден, создаем пустой объект
        }
      } catch (error) {
        this.resample_config = {}; // При ошибке также создаем пустой объект
      }
    },
  },


  async mounted() {
    // Chart can only be created when the component has mounted the DOM because
    // the chart needs the element with specified containerId to exist in the DOM
    // this.createChart()
    await this.beforeMnt();
    let tickers_cnt = this.tickers.length;
    if (tickers_cnt < this.cols) {
      this.cols = tickers_cnt;
    }
    this.createDashBoard();
    if (this.reload > 0) {
      setTimeout(function () {
        window.location.reload();
      }, 1000 * this.reload * 60);
    }
  },
  beforeDestroy() {
    // "dispose" should be called when the component is unmounted to free all the resources used by the chart
    if (this.chart !== null)
      this.dashboard.dispose()
  }
}
</script>
