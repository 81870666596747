<template>
  <div style="height: 100%; width: 100%;">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark py-0">
      <form class="form-inline">
        <input class="form-control bg-dark form-control-sm" type="search" placeholder="Search" aria-label="Search"
               id="search">
      </form>
      <div class="btn-group btn-group-sm" role="group" id="div_tf">
        <!--        <button type="button" class="btn btn-dark btn-sm" disabled>TF:</button>-->
      </div>
      <div class="btn-group btn-group-sm" role="group" id="div_hl">
        <button type="button" class="btn btn-dark btn-sm" disabled>HL:</button>
      </div>
      <div class="btn-group btn-group-sm" role="group" id="div_vl">
        <button type="button" class="btn btn-dark btn-sm" disabled>VL:</button>
      </div>
      <div class="btn-group btn-group-sm" role="group" id="div_bp">
        <button type="button" class="btn btn-dark btn-sm" disabled>BP:</button>
      </div>
      <div class="btn-group btn-group-sm" role="group" id="div_save">
      </div>
      <div class="btn-group btn-group-sm" role="group" id="div_nav">
        <button type="button" class="btn btn-dark btn-sm" id="data_prev"><<<</button>
        <button type="button" class="btn btn-dark btn-sm" id="data_next">>>></button>
      </div>
    </nav>
    <div :id="chartId" style="height:96%"></div>
  </div>
</template>

<script>
import {
  AxisScrollStrategies,
  AxisTickStrategies, ColorHEX,
  ColorRGBA,
  emptyFill, emptyLine, ImageFill, ImageFitMode, IndividualPointFill,
  lightningChart, LinearGradientFill,
  LUT, MouseStyles,
  OHLCFigures, OnScreenMenuButtonShape,
  PalettedFill, RadialGradientFill, SolidFill, SolidLine, synchronizeAxisIntervals,
  Themes, translatePoint, UIBackgrounds, UIDraggingModes, UIElementBuilders, UIOrigins,
} from '@arction/lcjs'
import {get} from "https";
import axios from "axios";
import FileSaver from 'file-saver';

const percentile = require('stats-percentile');

export default {
  name: 'HeatMap',
  // props: ['points'],
  data() {
    const license_key = typeof process.env.VUE_APP_LICENSE === 'undefined' ? '' : process.env.VUE_APP_LICENSE;
    this.lcjs = lightningChart({
      license: license_key,
      overrideInteractionMouseButtons: {
        chartXYPanMouseButton: 0,
        chartXYRectangleZoomFitMouseButton: 2,
      },
    })

    // Add the chart to the data in a way that Vue will not attach it's observers to it.
    // If the chart variable would be added in the return object, Vue would attach the observers and 
    // every time LightningChart JS made a change to any of it's internal variables, vue would try to observe the change and update.
    // Observing would slow down the chart a lot.
    this.tf = 60
    this.ticker = 'BINANCE_SPOT_BTC_USDT'
    this.timeStart = null
    this.vmax = 0
    this.vmin = 0
    this.chart = null
    this.dataBook = {}
    this.dataCandles = []
    this.heatmapSeries = null
    this.candleSeries = null
    this.api = 'https://api.trevax.com'
    this.boundary_percent = 10
    this.levels = 100
    this.endX = 0
    this.yMin = 0
    this.yMax = 0
    this.tsi = 0
    this.project_id = 0
    this.pub = false;
    this.wm = false;
    this.buttons = {};
    this.lines = [];
    this.lines_del = [];
    this.oldStyle = null;
    this.oldTitleStyle = null;
    this.lsData = {};
    this.rateSeries = null;
    this.P1Series = null;
    this.P2Series = null;
    this.P3Series = null;
    this.P4Series = null;
    this.interpolation = 0;
    this.no_book = 0;
    this.activeKey = null;
    this.y1 = null;
    this.y2 = null;
    this.y3 = null;
    this.reload = 0;
    this.db = 'trevax';

    return {
      chartId: null,
    }
  },
  methods: {
    addKbShortcuts() {
      window.document.onkeydown = function (event) {
        this.activeKey = event.key;
      }.bind(this);
      window.document.onkeyup = function (e) {
        if (e.code === 'KeyM' && e.shiftKey) {
          this.pub = !this.pub;
          if (this.pub) {
            window.document.getElementById('controls').style.display = 'none';
            window.document.getElementById(this.chartId).style.height = '100%';
            this.chart.engine.layout();
          } else {
            window.document.getElementById('controls').style.display = 'block';
            window.document.getElementById(this.chartId).style.height = '97%';
            this.chart.engine.layout();
          }
        } else if (e.code === 'KeyW' && e.shiftKey) {
          this.wm = !this.wm;
          if (this.wm) {
            this.addWatermark();
          } else {
            this.chart.setSeriesBackgroundFillStyle(this.oldStyle)
          }
        }
        // if (this.pub || this.wm) {
        //   if (null === this.oldTitleStyle) {
        //     this.oldTitleStyle = this.chart.getTitleFillStyle();
        //   }
        //   this.chart.setTitleFillStyle(emptyFill)
        // } else {
        //   this.chart.setTitleFillStyle(this.oldTitleStyle)
        //   this.chart.setTitle(this.ticker)
        // }
      }.bind(this);
    },
    addMenu() {
      // let div_timeframes = document.getElementById('div_timeframes')
      let div_tf = document.getElementById('div_tf');

      [1, 5, 10, 15, 30, 60, 120, 180, 240, 720, 1440].forEach(tf => {
        let a = document.createElement('button');
        let tfs = tf.toString() + 'M';
        if (tf === 1440) {
          tfs = 'D';
        } else if (tf >= 60) {
          tfs = (tf / 60).toString() + 'H';
        }
        let link = document.createTextNode(tfs);
        a.appendChild(link);
        a.className = "btn btn-dark btn-sm";
        a.href = "#";
        if (this.tf === tf)
          a.className = "btn btn-dark active";
        a.id = `TF_${tf}`
        a.onclick = function () {
          let tf = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('tf', tf);
          window.open(href.toString(), "_self")
          return false
        }
        div_tf.appendChild(a);
      });


      let div_hl = document.getElementById('div_hl');
      [60, 180, 240, 720, 1440].forEach(size => {
        let href = new URL(document.URL);
        let a = document.createElement('button');
        let link = document.createTextNode(size.toString());
        a.appendChild(link);
        a.href = "#";
        let hl = 0;
        if (href.searchParams.has('hl')) {
          hl = parseInt(href.searchParams.get('hl'))
        }
        a.className = "btn btn-dark btn-sm";
        if (size === hl)
          a.className = "btn btn-dark btn-sm active";
        a.id = `hl_${size}`
        a.onclick = function () {
          let hl = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('hl', hl);
          window.open(href.toString(), "_self")
          return false
        }
        div_hl.appendChild(a);
      });


      let div_vl = document.getElementById('div_vl');
      [50, 100, 200, 500, 1000].forEach(size => {
        let href = new URL(document.URL);
        let a = document.createElement('button');
        let link = document.createTextNode(size.toString());
        a.appendChild(link);
        a.href = "#";
        let levels = 0;
        if (href.searchParams.has('levels')) {
          levels = parseInt(href.searchParams.get('levels'))
        }
        a.className = "btn btn-dark btn-sm";
        if (size === levels)
          a.className = "btn btn-dark btn-sm active";

        a.id = `levels_${size}`
        a.onclick = function () {
          let levels = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('levels', levels);
          window.open(href.toString(), "_self")
          return false
        }
        div_vl.appendChild(a);
      });


      let div_bp = document.getElementById('div_bp');
      [1, 3, 5, 10, 15, 25, 30, 40, 50].forEach(prc => {
        let href = new URL(document.URL);
        let a = document.createElement('button');
        let link = document.createTextNode(prc.toString());
        a.appendChild(link);
        a.href = "#";

        let bp = 0;
        if (href.searchParams.has('bp')) {
          bp = parseInt(href.searchParams.get('bp'))
        }
        a.className = "btn btn-dark btn-sm";
        if (prc === bp)
          a.className = "btn btn-dark btn-sm active";
        a.id = `bp_${prc}`
        a.onclick = function () {
          let bp = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('bp', bp);
          window.open(href.toString(), "_self")
          return false
        }
        div_bp.appendChild(a);
      });


      let div_save = document.getElementById('div_save');
      {
        let href = new URL(document.URL);
        let a = document.createElement('button');
        let link = document.createTextNode('SAVE');
        a.appendChild(link);
        a.href = "#";
        a.className = "btn btn-dark btn-sm active";
        a.onclick = function () {
          let [exchange, market_type, tbase, tquote] = this.ticker.split('_')
          let ts = Math.trunc(Date.now() / 1000)
          let postfix = 'orig'
          if (this.wm) {
            postfix = 'wm'
          }
          let blob = new Blob([this.chart.engine.captureFrame()], {type: "image/png"});
          FileSaver.saveAs(blob, `${ts}_${tbase}_${tquote}_${postfix}`);
          return false
        }.bind(this)
        div_save.appendChild(a);

      }

      let href = new URL(document.URL);
      let link_prev = document.getElementById('data_prev')
      let link_next = document.getElementById('data_next')
      href.searchParams.delete('time_start')
      href.searchParams.delete('time_end')

      let ctsi = 0;
      if (href.searchParams.has('tsi')) {
        ctsi = parseInt(href.searchParams.get('tsi'));
      } else {
        ctsi = Math.round(new Date().getTime() / 1000 / this.tf / 60);
      }

      const tsi_prev = ctsi - Math.trunc(this.historyLength / 4 * 3)
      const tsi_next = ctsi + Math.trunc(this.historyLength / 4 * 3)

      href.searchParams.set('tsi', String(tsi_prev))
      const href_prev = JSON.parse(JSON.stringify(href));
      link_prev.onclick = function () {
        window.open(href_prev.toString(), "_self")
        return false
      }
      href.searchParams.set('tsi', String(tsi_next))
      const href_next = JSON.parse(JSON.stringify(href));
      link_next.onclick = function () {
        window.open(href_next.toString(), "_self")
        return false
      }
    },
    addWatermark() {
      if (null === this.oldStyle) {
        this.oldStyle = this.chart.getSeriesBackgroundFillStyle();
      }
      // https://javascript.plainenglish.io/how-to-build-an-image-watermarking-tool-with-html-canvas-9dd45bb4f13
      let [exchange, market_type, tbase, tquote] = this.ticker.split('_')
      let canvas = document.createElement("canvas");
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const getCoordinates = (position, imageWidth, imageHeight, canvasWidth, canvasHeight) => {
        const xCenter = canvasWidth / 2 - imageWidth / 2;
        const yCenter = canvasHeight / 2 - imageHeight / 2;
        switch (position) {
          case "top-left":
            return [0, 0];
          case "top-center":
            return [xCenter, 0];
          case "top-right":
            return [canvasWidth - imageWidth, 0];
          case "bottom-left":
            return [0, canvasHeight - imageHeight];
          case "bottom-center":
            return [xCenter, canvasHeight - imageHeight];
          case "bottom-right":
            return [canvasWidth - imageWidth, canvasHeight - imageHeight];
          case "center":
            return [xCenter, yCenter];
          case "center-left":
            return [0, yCenter]
          case "center-right":
            return [canvasWidth - imageWidth, yCenter]
          default:
            return [0, 0];
        }
      };
      const watermark_text = (canvas, text) => {
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = '#bdafaf';
        ctx.font = '64px Helvetica';
        ctx.textAlign = 'center';
        ctx.fillText(text, canvas.width / 2, canvas.height / 2);

      }
      const watermark_img = (canvas, watermarkImage, position, alpha) => {
        const ctx = canvas.getContext("2d");
        // ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.globalAlpha = alpha;
        const [x, y] = getCoordinates(
            position,
            watermarkImage.width,
            watermarkImage.height,
            canvas.width,
            canvas.height
        );
        ctx.drawImage(
            watermarkImage,
            x,
            y,
            watermarkImage.width,
            watermarkImage.height
        );
      };

      const watermarkImage = new Image()
      watermarkImage.crossOrigin = ''
      watermarkImage.src = '/chart_logo.png'
      watermarkImage.onload = () => {
        watermark_img(canvas, watermarkImage, 'top-left', 0.6);
        watermark_img(canvas, watermarkImage, 'top-center', 0.6);
        watermark_img(canvas, watermarkImage, 'top-right', 0.6);
        watermark_img(canvas, watermarkImage, 'bottom-left', 0.6);
        watermark_img(canvas, watermarkImage, 'bottom-center', 0.6);
        watermark_img(canvas, watermarkImage, 'bottom-right', 0.6);
        watermark_img(canvas, watermarkImage, 'center-left', 0.6);
        watermark_img(canvas, watermarkImage, 'center-right', 0.6);
        watermark_text(canvas, `${tbase}${tquote}, ${this.tf}`)
        const image = new Image();
        image.crossOrigin = ''
        image.src = canvas.toDataURL();
        image.onload = () => {
          canvas.remove();
          this.chart.setSeriesBackgroundFillStyle(
              new ImageFill({
                source: image,
                fitMode: ImageFitMode.Fit,
              }),
          )
        }
      }
    },
    addLine(tsi, dir) {
      let coord = (tsi * this.tf * 60 * 1000) - (this.timeStart * 1000)

      // direction/prediction: 0 = IGNORE, 1 = BUY, 2 = SELL, 3 = FADE/CLOSE, null = not processed

      let fill;
      switch (dir) {
        case 0:
          fill = new SolidFill({color: ColorHEX('#ffffff')}).setA(60);
          break;
        case 1:
          fill = new SolidFill({color: ColorHEX('#42ff25')}).setA(60);
          break;
        case 2:
          fill = new SolidFill({color: ColorHEX('#f00')}).setA(80);
          break;
        case 3:
          fill = new SolidFill({color: ColorHEX('#ff6f3d')}).setA(80);
          break;
      }
      const dirLine = this.chart.getDefaultAxisX().addConstantLine(true)
          .setName(`${tsi}:${dir}`)
          .setValue(coord)
          .setStrokeStyle(new SolidLine({
            thickness: 4,
            fillStyle: fill
          }))
          .setMouseInteractions(false)

      dirLine.onMouseUp((line, event) => {
        if (event.shiftKey) {
          let tsi = Math.trunc(((this.timeStart * 1000) + parseFloat(line.getValue())) / 1000 / 60 / this.tf)
          this.lines_del.push(tsi);
          const index = this.lines.indexOf(line);
          if (index > -1) {
            this.lines.splice(index, 1); // 2nd parameter means remove one item only
          }
          line.dispose();
        }
      });
      this.lines.push(dirLine)
    },
    saveMarkup() {
      let data = {
        'project_id': this.project_id,
        'tf': this.tf,
        'ticker': this.ticker,
        'status': 1
      };
      let lines = [];
      this.lines.forEach(line => {
        lines.push(line.getName())
      })

      if (lines.length > 0) {
        data['markups'] = lines;
        data['del'] = this.lines_del;
        axios.post('https://api.trevax.com/tools/markup', data, {}).then(function (response) {
          window.location.reload();
        })
      }
    },
    loadMarkup(ticker, time_start, time_end) {
      // if (this.tsi === 0)
      //   return;
      const tsi_min = Math.trunc(time_start / 60 / this.tf)
      const tsi_max = Math.trunc(time_end / 60 / this.tf)
      const url_markup = `${this.api}/tools/markup?project_id=${this.project_id}&ticker=${ticker}&tf=${this.tf}&tsi_start=${tsi_min}&tsi_end=${tsi_max}&pd=300&rnd=${Math.random()}`;
      let body = ''
      get(url_markup, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              const data = JSON.parse(body);
              this.applyMarkup(data)
            }.bind(this)
        )
      }.bind(this));
    },
    applyMarkup(data) {
      this.y2.setInterval(1, 5, false, true)
      this.y3.setInterval(0, 2, false, true)
      data.forEach(markup => {
        const ts = ((markup['tsi'] * 60) - this.timeStart) * 1000;
        const val = markup['volume_ask'] > markup['volume_bid'] ? -1 : 1;
        this.rateSeries.add([{x: ts, y: markup['volume_ratio'], value: val}]);
        this.P1Series.add([{x: ts, y: markup['y1']}]);
        this.P2Series.add([{x: ts, y: markup['y2']}]);
        this.P3Series.add([{x: ts, y: markup['y3']}]);
        this.P4Series.add([{x: ts, y: markup['y4']}]);
        if (markup['direction'] !== null) {
          this.addLine(markup['tsi'], markup['direction']);
        }
      })
    },
    applyCandles() {
      this.dataCandles.forEach(candle => {
        const high = candle[2]
        const low = candle[3]
        if (this.yMin === 0 || this.yMin > low)
          this.yMin = low
        if (this.yMax < high)
          this.yMax = high
      })
      this.candleSeries.clear()
      this.candleSeries.add(this.dataCandles)

      if (this.boundary_percent > 0) {
        const book_boundary = this.yMax / 100 * this.boundary_percent
        this.price_max = this.yMax + book_boundary
        this.price_min = this.yMin - book_boundary
        this.price_min < 0 ? 0 : this.price_min
      }

      this.chart.getDefaultAxisY().setInterval(this.price_min, this.price_max, false, true)
      // this.chart.getDefaultAxisX().setInterval(this.timeStart * 1000, this.timeEnd * 1000)
      this.loadBook(this.ticker, this.timeStart, this.timeEnd)
      this.loadMarkup(this.ticker, this.timeStart, this.timeEnd);
    },
    applyBooks() {
      let vol_all = []
      let max_vol = 0
      let new_book = Array(this.historyLength).fill(0.0).map(() => Array(this.levels))
      for (const [ts, data] of Object.entries(this.dataBook)) {
        for (const [price, volume] of Object.entries(data)) {
          try {
            if (volume >= this.vmin)
              new_book[ts][price] = volume
          } catch (e) {
          }
          if (volume > max_vol)
            max_vol = volume
          vol_all.push(volume)
        }
      }
      const p_low = percentile(vol_all, 75)
      const p_high = this.vmax > 0 ? this.vmax : percentile(vol_all, 99)
      const p_med = ((p_high - p_low) / 2) + p_low

      const xStep = this.tf * 60 * 1000

      // HEATMAP
      this.heatmapSeries = this.chart.addHeatmapGridSeries(
          {
            columns: this.historyLength,
            rows: this.levels,
            dataOrder: 'columns',
            start: {x: 0, y: this.price_min},
            // end: {x: this.timeEnd, y: this.price_max},
            step: {x: xStep, y: (this.price_max - this.price_min) / this.levels},
            yAxis: this.y1
          }
      );
      this.heatmapSeries.setWireframeStyle(emptyLine)
          .setFillStyle(new PalettedFill({
            lookUpProperty: 'value',
            lut: new LUT({
              interpolate: true,
              steps: [
                {value: 0, color: ColorRGBA(0, 0, 0, 8)},
                {value: p_med / 2, color: ColorRGBA(252, 221, 0, 64)},
                {value: p_med, color: ColorRGBA(252, 221, 0, 128)},
                {value: p_high, color: ColorRGBA(250, 0, 0, 200)},
              ]
            })
          }))
      if (this.interpolation === 0) {
        this.heatmapSeries.setIntensityInterpolation('disabled');
      } else {
        this.heatmapSeries.setIntensityInterpolation('bilinear');
      }

      // .setWireframeStyle(emptyLine)
      if (this.project_id > 0 && this.tsi > 0) {
        this.heatmapSeries.setCursorResultTableFormatter((builder, series, dataPoint) => builder
            .addRow('VOL', '', dataPoint.intensity.toFixed(1))
            .addRow('TSI:', '', String(Math.trunc(((this.timeStart * 1000) + dataPoint.x) / 1000 / 60 / this.tf)))
        )
      } else {
        this.heatmapSeries.setCursorResultTableFormatter((builder, series, dataPoint) => builder
                .addRow('VOL', '', dataPoint.intensity.toFixed(1))
                .addRow('TSI:', '', String(Math.trunc(((this.timeStart * 1000) + dataPoint.x) / 1000 / 60 / this.tf)))
            // .addRow('ASK VOL:', '', this.getLsData(dataPoint, 'ask_volume'))
            // .addRow('BID VOL:', '', this.getLsData(dataPoint, 'bid_volume'))
            // .addRow('VOL RT:', '', this.getLsData(dataPoint, 'volume_ratio'))
        )
      }
      this.heatmapSeries.invalidateIntensityValues(new_book)

    },
    loadBook(ticker, time_start, time_end) {
      if (this.no_book === 1)
        return;
      const url_book = `${this.api}/book/hmlevels?ticker=${ticker}&tf=${this.tf}&time_start=${time_start}&time_end=${time_end}&pmin=${this.price_min}&pmax=${this.price_max}&levels=${this.levels}&db=${this.db}`
      let body = ''
      get(url_book, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              this.dataBook = {}
              const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
              data.forEach(el => {
                const x = el[0];// * this.tf * 60 * 1000;
                const y = el[1]
                const v = el[2]
                if (!(x in this.dataBook))
                  this.dataBook[x] = {}
                if (!(y in this.dataBook[x]))
                  this.dataBook[x][y] = v
                else
                  this.dataBook[x][y] += v
              })
              this.applyBooks()
            }.bind(this)
        )
      }.bind(this));
    },
    loadCandles(ticker, time_start, time_end) {
      // if (ticker.includes('_FTS_'))
      //   ticker = ticker.replace('_FTS_', '_SPOT_')
      const url_candles = `${this.api}/candles?ticker=${ticker}&tf=${this.tf}&time_start=${time_start}&time_end=${time_end}&db=${this.db}`
      let body = ''
      get(url_candles, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
              let tsArray = [];
              data.forEach(el => {
                tsArray.push(el[0])
              })
              tsArray.sort(function (a, b) {
                return a - b;
              });
              while (tsArray.length > this.historyLength) {
                tsArray.shift();
              }
              data.forEach(el => {
                if (tsArray.includes(el[0])) {
                  let ts = el[0];
                  let open = el[1];
                  let high = el[2];
                  let low = el[3];
                  let close = el[4];

                  if (open > close) { // DOWN
                    if ((high - open) / (open - close) >= 3)
                      high = open;
                    if ((close - low) / (open - close) >= 3)
                      low = close;
                  } else if (open < close) { // UP
                    if ((high - close) / (close - open) >= 3)
                      high = close;
                    if ((open - low) / (close - open) >= 3)
                      low = open;
                  } else {
                    high = close;
                    low = close;
                  }
                  this.dataCandles.push([(ts - this.timeStart) * 1000, open, high, low, close]);
                }
              })
              // this.timeStart = tsArray.shift();
              // this.timeEnd = tsArray.pop();
              this.applyCandles()
            }.bind(this)
        )
      }.bind(this));
    },
    createChart() {
      this.chart = this.lcjs.ChartXY({
        columnIndex: 0,
        rowIndex: 0,
        columnSpan: 1,
        rowSpan: 1,
        theme: Themes.darkGreen,
        container: `${this.chartId}`,
        defaultAxisY: {opposite: true,}
      })
      this.y1 = this.chart.getDefaultAxisY();
      document.getElementById('search').value = this.ticker;

      // this.chart.setTitle(this.ticker)
      // this.chart.setTitleMarginTop(0)
      // this.chart.setTitleMarginBottom(0)
      this.chart.setTitleFillStyle(emptyFill)
      this.chart.setMouseInteractions(false)
      this.chart.disableAnimations()
      // https://stackoverflow.com/questions/59934089/how-to-disable-zooming-animation-on-loading-lightningchart-js
      this.chart.getDefaultAxisX().setAnimationZoom(undefined)
      this.chart.getDefaultAxisY().setAnimationZoom(undefined)
      let tickStrategy = this.chart.getDefaultAxisY().getTickStrategy()
      this.chart.getDefaultAxisY().setTickStrategy(
          // Use Numeric TickStrategy as Base.
          tickStrategy,
          // Use a mutator to modify the TickStrategy.
          (tickStrategy) => tickStrategy
              // Modify the Major Ticks for the TickStrategy.
              // Minor and Extreme TickStyles must be set separately.
              .setMajorTickStyle(visibleTicks => visibleTicks
                  .setLabelFont(font => font
                      .setSize(12)
                      .setWeight('lighter')
                  )
                  // .setTickStyle(emptyLine)
                  // .setGridStrokeStyle(emptyLine)
                  .setGridStrokeStyle(new SolidLine({
                    fillStyle: new SolidFill({color: ColorHEX('#00ff00').setA(80)}),
                    thickness: -1
                  }))
                  .setLabelFillStyle(new SolidFill({color: ColorHEX('#00ff00'), tickLength: 8}))
                  .setTickStyle(new SolidLine({fillStyle: new SolidFill({color: ColorHEX('#00ff00')}), thickness: -1}))
              )

              .setMinorTickStyle(tickStrategy.getMajorTickStyle())
              .setMinorTickStyle(visibleTicks => visibleTicks
                  // .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine))
          // .setGridStrokeStyle(new SolidLine({
          //   fillStyle: new SolidFill({color: ColorHEX('#e3fc40').setA(80)}),
          //   thickness: -1
          // }))
          // .setLabelFillStyle(new SolidFill({color: ColorHEX('#e3fc40'), tickLength: 8}))
          // .setTickStyle(new SolidLine({fillStyle: new SolidFill({color: ColorHEX('#00ff00')}), thickness: -1})))
      )

      this.chart.getDefaultAxisX().setTickStrategy(
          AxisTickStrategies.DateTime,
          (tickStrategy) => tickStrategy.setDateOrigin(new Date(this.timeStart * 1000))
              .setMajorTickStyle(visibleTicks => visibleTicks
                  .setLabelFont(font => font
                      .setSize(12)
                      .setWeight('lighter')
                  )
                  .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine)
              )
              .setMinorTickStyle(tickStrategy.getMajorTickStyle())
              .setMinorTickStyle(visibleTicks => visibleTicks
                  .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine))
      )
      this.chart.getDefaultAxisY()
          .setAxisInteractionZoomByDragging(false)
          .setAxisInteractionZoomByWheeling(false)
          .setChartInteractions(false)
          .setMouseInteractions(false)

      this.chart.getDefaultAxisX()
          .setScrollStrategy(AxisScrollStrategies.fitting)
          .setChartInteractionFitByDrag(false)
          .setChartInteractionZoomByDrag(false)
          .setChartInteractionPanByDrag(false)
          .setChartInteractionZoomByWheel(false)
          .setAxisInteractionZoomByDragging(false)
          .setAxisInteractionZoomByWheeling(false)
          .setChartInteractions(false)
          .setMouseInteractions(false)

      this.candleSeries = this.chart.addOHLCSeries({positiveFigure: OHLCFigures.Candlestick})
          .setFigureAutoFitting(false)

      this.y2 = this.chart.addAxisY(false)
          .setAxisInteractionZoomByDragging(false)
          .setAxisInteractionZoomByWheeling(false)
          .setChartInteractions(false)
          .setMouseInteractions(false)

      this.y3 = this.chart.addAxisY(false)
          .setAxisInteractionZoomByDragging(false)
          .setAxisInteractionZoomByWheeling(false)
          .setChartInteractions(false)
          .setMouseInteractions(false)

      this.rateSeries = this.chart.addLineSeries({
        individualLookupValuesEnabled: true,
        yAxis: this.y2
      })

      this.P1Series = this.chart.addPointSeries({yAxis: this.y3})
      this.P2Series = this.chart.addPointSeries({yAxis: this.y3})
      this.P3Series = this.chart.addPointSeries({yAxis: this.y3})
      this.P4Series = this.chart.addPointSeries({yAxis: this.y3})

      this.rateSeries.setStrokeStyle(new SolidLine({
        thickness: 2,
        fillStyle: new PalettedFill({
          lookUpProperty: 'value',
          lut: new LUT({
            interpolate: false,
            steps: [
              {value: -1, color: ColorRGBA(255, 0, 0)},
              {value: 1, color: ColorRGBA(0, 255, 0)},
            ]
          })
        })
      }))

      this.P1Series.setPointFillStyle(new SolidFill({color: ColorHEX('#ffffff')}).setA(255))
      this.P2Series.setPointFillStyle(new SolidFill({color: ColorHEX('#42ff25')}).setA(255))
      this.P3Series.setPointFillStyle(new SolidFill({color: ColorHEX('#f00')}).setA(255))
      this.P4Series.setPointFillStyle(new SolidFill({color: ColorHEX('#ff6f3d')}).setA(255))

      this.y2.addConstantLine(false)
          .setValue(1.6)
          .setStrokeStyle(new SolidLine({
            thickness: 4,
            fillStyle: new SolidFill({color: ColorHEX('#0048ff')}).setA(80)
          }))
          .setMouseInteractions(false)
      this.y2.addConstantLine(false)
          .setValue(1.3)
          .setStrokeStyle(new SolidLine({
            thickness: 4,
            fillStyle: new SolidFill({color: ColorHEX('#00ff00')}).setA(80)
          }))
          .setMouseInteractions(false)
      this.y2.addConstantLine(false)
          .setValue(1.1)
          .setStrokeStyle(new SolidLine({
            thickness: 4,
            fillStyle: new SolidFill({color: ColorHEX('#ff6f3d')}).setA(80)
          }))
          .setMouseInteractions(false)


      this.y3.addConstantLine(false)
          .setValue(1)
          .setStrokeStyle(new SolidLine({
            thickness: 4,
            fillStyle: new SolidFill({color: ColorHEX('#FF6F3D')}).setA(100)
          }))
          .setMouseInteractions(false)

      this.y3.addConstantLine(false)
          .setValue(0.7)
          .setStrokeStyle(new SolidLine({
            thickness: 4,
            fillStyle: new SolidFill({color: ColorHEX('#FF6F3D')}).setA(100)
          }))
          .setMouseInteractions(false)

      this.y3.addConstantLine(false)
          .setValue(0.5)
          .setStrokeStyle(new SolidLine({
            thickness: 4,
            fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(100)
          }))
          .setMouseInteractions(false)


      let y2_tickStrategy = this.y2.getTickStrategy()
      this.y2.setTickStrategy(
          // Use Numeric TickStrategy as Base.
          tickStrategy,
          // Use a mutator to modify the TickStrategy.
          (tickStrategy) => tickStrategy
              // Modify the Major Ticks for the TickStrategy.
              // Minor and Extreme TickStyles must be set separately.
              .setMajorTickStyle(visibleTicks => visibleTicks
                  .setLabelFont(font => font
                      .setSize(12)
                      .setWeight('lighter')
                  )
                  .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine)
              )
              .setMinorTickStyle(tickStrategy.getMajorTickStyle())
              .setMinorTickStyle(visibleTicks => visibleTicks
                  .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine))
      )

      this.addMenu();
      this.addKbShortcuts();

      if (this.tsi > 0) {
        let last_x = 8;
        const button_save = this.chart.addUIElement(UIElementBuilders.ButtonBox.setBackground(UIBackgrounds.Rectangle))
            .setPosition(translatePoint({
              x: last_x,
              y: this.chart.engine.container.getBoundingClientRect().height - 8
            }, this.chart.engine.scale, this.chart.uiScale))
            .setOrigin(UIOrigins.LeftTop)
            .setText('Save')
            .setPadding({top: 5, right: 20, bottom: 5, left: 20})
            .setButtonOffSize(0)
            .setButtonOnSize(0)
            .setDraggingMode(UIDraggingModes.notDraggable)
            .setBackground(bg => bg
                .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
            )
            .onMouseClick((event) => {
              this.saveMarkup()
            });

      }
      this.chart.onSeriesBackgroundMouseClick((obj, event) => {
        const a_key = parseInt(this.activeKey);
        if (a_key >= 0 && a_key <= 3) {
          const mouseLocationEngine = this.chart.engine.clientLocation2Engine(event.clientX, event.clientY)
          const mouseLocationAxis = translatePoint(mouseLocationEngine, this.chart.engine.scale, {
            x: this.chart.getDefaultAxisX(),
            y: this.chart.getDefaultAxisY()
          })

          let tsi = Math.trunc(((this.timeStart * 1000) + mouseLocationAxis.x) / 1000 / 60 / this.tf)
          this.addLine(tsi, a_key);
        }

      })
      this.loadCandles(this.ticker, this.timeStart, this.timeEnd)
    },
  },
  async beforeMount() {
    // Generate random ID to us as the containerId for the chart and the target div id
    this.chartId = Math.trunc(Math.random() * 1000000)
    this.tf = !(this.$route.query.tf == null) ? parseInt(this.$route.query.tf) : 60;
    this.historyLength = !(this.$route.query.hl == null) ? parseInt(this.$route.query.hl) : 100;
    this.price_min = !(this.$route.query.pmin == null) ? parseFloat(this.$route.query.pmin) : 0;
    this.price_max = !(this.$route.query.pmax == null) ? parseFloat(this.$route.query.pmax) : 0;
    this.boundary_percent = !(this.$route.query.bp == null) ? parseInt(this.$route.query.bp) : 10;
    this.levels = !(this.$route.query.levels == null) ? parseInt(this.$route.query.levels) : 100;
    const currentTSI = Math.round(new Date().getTime() / 1000 / this.tf / 60);
    this.timeStart = this.$route.query.time_start || (currentTSI - this.historyLength) * this.tf * 60;
    // this.timeStart = this.$route.query.time_start || Math.round((Math.round(new Date().getTime() / 1000) - ((this.historyLength - 1) * this.tf * 60)) / (60 * this.tf)) * (60 * this.tf)
    this.timeEnd = this.$route.query.time_end || (currentTSI + 1) * this.tf * 60;
    // this.timeEnd = this.$route.query.time_end || this.timeStart + (this.historyLength * this.tf * 60)
    this.tsi = !(this.$route.query.tsi == null) ? parseInt(this.$route.query.tsi) : 0;
    this.project_id = !(this.$route.query.pid == null) ? parseInt(this.$route.query.pid) : 0;
    this.vmax = !(this.$route.query.vmax == null) ? parseInt(this.$route.query.vmax) : 0;
    this.vmin = !(this.$route.query.vmin == null) ? parseInt(this.$route.query.vmin) : 0;
    this.wm = !(this.$route.query.wm == null) ? parseInt(this.$route.query.wm) : false;
    this.interpolation = !(this.$route.query.ip == null) ? parseInt(this.$route.query.ip) : 0;
    this.no_book = !(this.$route.query.nb == null) ? parseInt(this.$route.query.nb) : 0;
    this.reload = !(this.$route.query.reload == null) ? parseInt(this.$route.query.reload) : 0;
    this.db = 'trevax';
    if (this.$route.query.db === 'trevax_metrics' || this.$route.query.db === 'scoins')
      this.db = this.$route.query.db;
    if (!(this.$route.query.ticker == null)) {
      this.ticker = this.$route.query.ticker
    }

    if (!(this.$route.query.title == null)) {
      document.title = this.$route.query.title;
    } else {
      document.title = this.ticker;
    }

    if (this.tsi === -1) {
      let httpRequest = new XMLHttpRequest();
      httpRequest.open("GET", `https://api.trevax.com/tools/markup_rand?tf=${this.tf}&pid=${this.project_id}`, false);
      httpRequest.send()
      if (httpRequest.status !== 200) {
      } else {
        let data = JSON.parse(httpRequest.response)
        this.tsi = data['tsi']
        this.ticker = data['ticker']
      }
    }
    if (this.tsi > 0) {
      this.timeStart = (this.tsi - Math.round(this.historyLength / 2) + 2) * this.tf * 60;
      this.timeEnd = (this.tsi + Math.round(this.historyLength / 2)) * this.tf * 60;
    }
  },
  mounted() {
    // Chart can only be created when the component has mounted the DOM because
    // the chart needs the element with specified containerId to exist in the DOM
    //this.createDashBoard()
    this.createChart()
    if (this.reload > 0) {
      setTimeout(function () {
        window.location.reload();
      }, 1000 * this.reload * 60);
    }
  },
  beforeDestroy() {
    // "dispose" should be called when the component is unmounted to free all the resources used by the chart
    if (this.chart !== null)
      this.chart.dispose()
  }
}
</script>
