<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
html {
  height: 100%;
  background-color: black;
  margin: 0;
}

body {
  height: 100%;
  background-color: black;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  margin: 0;
}
</style>
