<template>
  <div style="height: 100%; width: 100%;">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark py-0">
      <div class="btn-group btn-group-sm" role="group" id="div_tf">
        <!--        <button type="button" class="btn btn-dark btn-sm" disabled>TF:</button>-->
      </div>
      <div class="btn-group btn-group-sm" role="group" id="div_hl">
        <button type="button" class="btn btn-dark btn-sm" disabled>HL:</button>
      </div>
      <div class="btn-group btn-group-sm" role="group" id="div_vl">
        <button type="button" class="btn btn-dark btn-sm" disabled>VL:</button>
      </div>
      <div class="btn-group btn-group-sm" role="group" id="div_bp">
        <button type="button" class="btn btn-dark btn-sm" disabled>BP:</button>
      </div>
      <div class="btn-group btn-group-sm" role="group" id="div_nav">
        <button type="button" class="btn btn-dark btn-sm" id="data_prev"><<<</button>
        <button type="button" class="btn btn-dark btn-sm" id="data_next">>>></button>
      </div>
    </nav>
    <div :id="chartId" style="height:96%"></div>
  </div>
</template>

<script>
import {
  AxisScrollStrategies,
  AxisTickStrategies,
  ColorHEX,
  ColorRGBA,
  emptyFill,
  emptyLine,
  ImageFill,
  ImageFitMode,
  IndividualPointFill,
  lightningChart,
  LinearGradientFill,
  LUT,
  MouseStyles,
  OHLCFigures,
  OnScreenMenuButtonShape,
  PalettedFill,
  RadialGradientFill,
  SolidFill,
  SolidLine,
  synchronizeAxisIntervals,
  Themes,
  translatePoint,
  UIBackgrounds,
  UIDraggingModes,
  UIElementBuilders,
  UIOrigins,
  AreaSeriesTypes,
} from '@arction/lcjs'
import {get} from "https";
import axios from "axios";

const percentile = require('stats-percentile');


function sma(indata, window) {
  const len = indata.length
  const result = []
  const yValueBuffer = []
  let sum = 0
  for (let i = 0; i < len; i++) {
    const x = indata[i].x
    const y = indata[i].y
    sum += y
    if (i >= window - 1) {
      const curAvg = sum / window
      result.push({x: x, y: curAvg})
      const droppedValue = yValueBuffer.shift()
      sum -= droppedValue
    }
    yValueBuffer.push(y)
  }
  return result
}

function ema(indata, window) {
  const len = indata.length
  const result = []
  const weighingMultiplier = 2 / (window + 1)

  // Calculate initial previous EMA using SMA method.
  let i
  let previousEMASum = 0
  for (i = 0; i < window; i++) {
    const x = indata[i].x
    const y = indata[i].y
    previousEMASum += y
  }
  let previousEMA = previousEMASum / window
  for (; i < len; i++) {
    const x = indata[i].x
    const y = indata[i].y
    // Compute current EMA value.
    const currentEMA = y * weighingMultiplier + (previousEMA !== undefined ? previousEMA * (1 - weighingMultiplier) : 0)
    if (i >= window - 1) {
      result.push({x: x, y: currentEMA})
    }
    previousEMA = currentEMA
  }
  return result
}

function cumSum(indata, window) {
  const len = indata.length
  const result = []
  const yValueBuffer = []
  let sum = 0
  for (let i = 0; i < len; i++) {
    const x = indata[i].x
    const y = indata[i].y
    sum += y
    if (i >= window - 1) {
      result.push({x: x, y: sum})
      const droppedValue = yValueBuffer.shift()
      sum -= droppedValue
    }
    yValueBuffer.push(y)
  }
  return result
}

export default {
  name: 'Markup',
  data() {
    const license_key = typeof process.env.VUE_APP_LICENSE === 'undefined' ? '' : process.env.VUE_APP_LICENSE;
    this.lcjs = lightningChart({
      license: license_key,
      overrideInteractionMouseButtons: {
        chartXYPanMouseButton: 0,
        chartXYRectangleZoomFitMouseButton: 2,
      },
    })

    // Add the chart to the data in a way that Vue will not attach it's observers to it.
    // If the chart variable would be added in the return object, Vue would attach the observers and 
    // every time LightningChart JS made a change to any of it's internal variables, vue would try to observe the change and update.
    // Observing would slow down the chart a lot.
    this.tf = 60
    this.ticker = 'BINANCE_SPOT_BTC_USDT'
    this.timeStart = null
    this.vmax = 0
    this.vmin = 0
    this.dashboard = null
    this.charts = {}
    this.series = {}
    this.dataBook = {}
    this.dataCandles = []
    this.dataVolumes = []
    this.api = 'https://api.trevax.com'
    this.boundary_percent = 10
    this.levels = 100
    this.endX = 0
    this.yMin = 0
    this.yMax = 0
    this.tsi = 0
    this.project_id = 0;
    this.buttons = {};
    this.lines = [];
    this.lines_del = [];
    this.interpolation = 0;
    this.no_book = 0;
    this.activeKey = null;
    this.pds = [10, 20, 30, 40, 50, 100, 150, 200, 250, 300].reverse();
    this.reload = 0;
    this.prediction_min_level = 0.0;
    this.P0Series = null;
    this.P1Series = null;
    this.P2Series = null;
    this.P3Series = null;
    this.db = 'trevax';

    return {
      chartId: null,
    }
  },
  methods: {
    addKbShortcuts() {
      window.document.onkeydown = function (event) {
        this.activeKey = event.key;
      }.bind(this);
    },
    addMenu() {
      // let div_timeframes = document.getElementById('div_timeframes')
      let div_tf = document.getElementById('div_tf');

      [1, 5, 10, 15, 30, 60, 120, 180, 240, 720, 1440].forEach(tf => {
        let a = document.createElement('button');
        let tfs = tf.toString() + 'M';
        if (tf === 1440) {
          tfs = 'D';
        } else if (tf >= 60) {
          tfs = (tf / 60).toString() + 'H';
        }
        let link = document.createTextNode(tfs);
        a.appendChild(link);
        a.className = "btn btn-dark btn-sm";
        a.href = "#";
        if (this.tf === tf)
          a.className = "btn btn-dark active";
        a.id = `TF_${tf}`
        a.onclick = function () {
          let tf = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('tf', tf);
          window.open(href.toString(), "_self")
          return false
        }
        div_tf.appendChild(a);
      });


      let div_hl = document.getElementById('div_hl');
      [60, 180, 240, 720, 1440].forEach(size => {
        let href = new URL(document.URL);
        let a = document.createElement('button');
        let link = document.createTextNode(size.toString());
        a.appendChild(link);
        a.href = "#";
        let hl = 0;
        if (href.searchParams.has('hl')) {
          hl = parseInt(href.searchParams.get('hl'))
        }
        a.className = "btn btn-dark btn-sm";
        if (size === hl)
          a.className = "btn btn-dark btn-sm active";
        a.id = `hl_${size}`
        a.onclick = function () {
          let hl = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('hl', hl);
          window.open(href.toString(), "_self")
          return false
        }
        div_hl.appendChild(a);
      });


      let div_vl = document.getElementById('div_vl');
      [50, 100, 200, 500, 1000].forEach(size => {
        let href = new URL(document.URL);
        let a = document.createElement('button');
        let link = document.createTextNode(size.toString());
        a.appendChild(link);
        a.href = "#";
        let levels = 0;
        if (href.searchParams.has('levels')) {
          levels = parseInt(href.searchParams.get('levels'))
        }
        a.className = "btn btn-dark btn-sm";
        if (size === levels)
          a.className = "btn btn-dark btn-sm active";

        a.id = `levels_${size}`
        a.onclick = function () {
          let levels = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('levels', levels);
          window.open(href.toString(), "_self")
          return false
        }
        div_vl.appendChild(a);
      });


      let div_bp = document.getElementById('div_bp');
      [1, 3, 5, 10, 15, 25, 30, 40, 50].forEach(prc => {
        let href = new URL(document.URL);
        let a = document.createElement('button');
        let link = document.createTextNode(prc.toString());
        a.appendChild(link);
        a.href = "#";

        let bp = 0;
        if (href.searchParams.has('bp')) {
          bp = parseInt(href.searchParams.get('bp'))
        }
        a.className = "btn btn-dark btn-sm";
        if (prc === bp)
          a.className = "btn btn-dark btn-sm active";
        a.id = `bp_${prc}`
        a.onclick = function () {
          let bp = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('bp', bp);
          window.open(href.toString(), "_self")
          return false
        }
        div_bp.appendChild(a);
      });

      let href = new URL(document.URL);
      let link_prev = document.getElementById('data_prev')
      let link_next = document.getElementById('data_next')
      href.searchParams.delete('time_start')
      href.searchParams.delete('time_end')

      let ctsi = 0;
      if (href.searchParams.has('tsi')) {
        ctsi = parseInt(href.searchParams.get('tsi'));
      } else {
        ctsi = Math.round(new Date().getTime() / 1000 / this.tf / 60);
      }

      const tsi_prev = ctsi - Math.trunc(this.historyLength / 4 * 3)
      const tsi_next = ctsi + Math.trunc(this.historyLength / 4 * 3)

      href.searchParams.set('tsi', String(tsi_prev))
      const href_prev = JSON.parse(JSON.stringify(href));
      link_prev.onclick = function () {
        window.open(href_prev.toString(), "_self")
        return false
      }
      href.searchParams.set('tsi', String(tsi_next))
      const href_next = JSON.parse(JSON.stringify(href));
      link_next.onclick = function () {
        window.open(href_next.toString(), "_self")
        return false
      }
    },
    addLine(tsi, dir) {
      let coord = (tsi * this.tf * 60 * 1000) - (this.timeStart * 1000)

      // direction/prediction: 0 = IGNORE, 1 = BUY, 2 = SELL, 3 = FADE/CLOSE, null = not processed

      let fill;
      switch (dir) {
        case 0:
          fill = new SolidFill({color: ColorHEX('#ffffff')}).setA(60);
          break;
        case 1:
          fill = new SolidFill({color: ColorHEX('#42ff25')}).setA(60);
          break;
        case 2:
          fill = new SolidFill({color: ColorHEX('#f00')}).setA(80);
          break;
        case 3:
          fill = new SolidFill({color: ColorHEX('#ff6f3d')}).setA(80);
          break;
      }
      const dirLine = this.charts['main'].getDefaultAxisX().addConstantLine(true)
          .setName(`${tsi}:${dir}`)
          .setValue(coord)
          .setStrokeStyle(new SolidLine({
            thickness: 4,
            fillStyle: fill
          }))
          .setMouseInteractions(true)

      dirLine.onMouseUp((line, event) => {
        if (event.shiftKey) {
          let tsi = Math.trunc(((this.timeStart * 1000) + parseFloat(line.getValue())) / 1000 / 60 / this.tf)
          this.lines_del.push(tsi);
          const index = this.lines.indexOf(line);
          if (index > -1) {
            this.lines.splice(index, 1); // 2nd parameter means remove one item only
          }
          line.dispose();
        }
      });
      this.lines.push(dirLine)
    },
    saveMarkup() {
      let data = {
        'project_id': this.project_id,
        'tf': this.tf,
        'ticker': this.ticker,
        'status': 1
      };
      let lines = [];
      this.lines.forEach(line => {
        lines.push(line.getName())
      })

      if (lines.length > 0) {
        data['markups'] = lines;
        data['del'] = this.lines_del;
        axios.post('https://api.trevax.com/tools/markup', data, {}).then(function (response) {
          window.location.reload();
        })
      }
    },
    loadStats(ticker, time_start, time_end) {
      const tsi_min = Math.trunc(time_start / 60 / this.tf)
      const tsi_max = Math.trunc(time_end / 60 / this.tf)
      const url_stats = `${this.api}/book/stats?ticker=${ticker}&tf=${this.tf}&tsi_start=${tsi_min}&tsi_end=${tsi_max}&db=${this.db}&rnd=${Math.random()}`;
      let body = ''
      get(url_stats, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              const data = JSON.parse(body);
              this.applyStats(data)
            }.bind(this)
        )
      }.bind(this));
    },
    applyStats(data) {
      let values = {}
      let asks = {}
      let bids = {}
      data.forEach(markup => {
        const ts = (markup['uts'] - this.timeStart) * 1000;
        const pd = parseFloat(markup['pd']);
        // const ratio = markup['ratio']
        const ask = markup['ask']
        const bid = markup['bid']
        // const val = markup['ask'] > markup['bid'] ? -1 : 1;
        if (!(pd.toString() in values)) {
          values[pd.toString()] = []
          asks[pd.toString()] = []
          bids[pd.toString()] = []
        }
        // values[pd.toString()].push({x: ts, y: ratio, value: val})
        asks[pd.toString()].push({x: ts, y: ask})
        bids[pd.toString()].push({x: ts, y: bid})
      })
      // for (const [pd, vals] of Object.entries(values)) {
      //   if (this.pds.includes(parseFloat(pd))) {
      // this.series[`rate_${pd}`].add(vals)
      // this.series[`rate_${pd}_avg_slow`].add(sma(vals, 5))
      // this.series[`rate_${pd}_avg_fast`].add(sma(vals, 15))
      // }
      // }
      for (const [pd, vals] of Object.entries(asks)) {
        if (this.pds.includes(parseFloat(pd))) {
          this.series[`rate_${pd}_asks`].add(vals)
        }
      }
      for (const [pd, vals] of Object.entries(bids)) {
        if (this.pds.includes(parseFloat(pd))) {
          this.series[`rate_${pd}_bids`].add(vals)
        }
      }
    },
    loadMarkup(ticker, time_start, time_end) {
      const tsi_min = Math.trunc(time_start / 60 / this.tf)
      const tsi_max = Math.trunc(time_end / 60 / this.tf)
      const url_markup = `${this.api}/tools/markup?pid=${this.project_id}&ticker=${ticker}&tf=${this.tf}&tsi_start=${tsi_min}&tsi_end=${tsi_max}&db=${this.db}&rnd=${Math.random()}`;
      let body = ''
      get(url_markup, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              const data = JSON.parse(body);
              this.applyMarkup(data)
            }.bind(this)
        )
      }.bind(this));
    },
    applyMarkup(data) {
      // data.forEach(markup => {
      //   const res = [markup['p0'], markup['p1'], markup['p2'], markup['p3']];
      //   const prediction = res.indexOf(Math.max(...res));
      //   const prediction_level = res[prediction] / res.reduce((a, b) => a + b, 0)
      //   if (prediction_level >= this.prediction_min_level && prediction_level <= this.prediction_max_level)
      //     this.addLine(markup['tsi'], prediction);

      data.forEach(markup => {
        if (markup['direction'] !== null)
          this.addLine(markup['tsi'], markup['direction']);
        let coord = (markup['tsi'] * this.tf * 60 * 1000) - (this.timeStart * 1000)

        if (markup['y0'] >= this.prediction_min_level)
          this.P0Series.add([{x: coord, y: markup['y0']}]);
        if (markup['y1'] >= this.prediction_min_level)
          this.P1Series.add([{x: coord, y: markup['y1']}]);
        if (markup['y2'] >= this.prediction_min_level)
          this.P2Series.add([{x: coord, y: markup['y2']}]);
        if (markup['y3'] >= this.prediction_min_level)
          this.P3Series.add([{x: coord, y: markup['y3']}]);
      })
    },
    applyCandles() {
      this.dataCandles.forEach(candle => {
        const high = candle[2]
        const low = candle[3]
        if (this.yMin === 0 || this.yMin > low)
          this.yMin = low
        if (this.yMax < high)
          this.yMax = high
      })
      this.series['candles'].clear()
      this.series['candles'].add(this.dataCandles)
      this.series['volume'].add(this.dataVolumes)
      this.series['volume_cs'].add(cumSum(this.dataVolumes, 5))

      if (this.boundary_percent > 0) {
        const book_boundary = this.yMax / 100 * this.boundary_percent
        this.price_max = this.yMax + book_boundary
        this.price_min = this.yMin - book_boundary
        this.price_min < 0 ? 0 : this.price_min
      }

      this.charts['main'].getDefaultAxisY().setInterval(this.price_min, this.price_max, false, true)
      this.loadBook(this.ticker, this.timeStart, this.timeEnd)
      this.loadMarkup(this.ticker, this.timeStart, this.timeEnd);
      this.loadStats(this.ticker, this.timeStart, this.timeEnd);
    },
    applyBooks() {
      let vol_all = []
      let max_vol = 0
      let new_book = Array(this.historyLength).fill(0.0).map(() => Array(this.levels))
      for (const [ts, data] of Object.entries(this.dataBook)) {
        for (const [price, volume] of Object.entries(data)) {
          try {
            if (volume >= this.vmin)
              new_book[ts][price] = volume
          } catch (e) {
          }
          if (volume > max_vol)
            max_vol = volume
          vol_all.push(volume)
        }
      }
      const p_low = percentile(vol_all, 75)
      const p_high = this.vmax > 0 ? this.vmax : percentile(vol_all, 99)
      const p_med = ((p_high - p_low) / 2) + p_low

      const xStep = this.tf * 60 * 1000

      // HEATMAP
      this.series['heatmap'] = this.charts['main'].addHeatmapGridSeries(
          {
            columns: this.historyLength,
            rows: this.levels,
            dataOrder: 'columns',
            start: {x: 0, y: this.price_min},
            step: {x: xStep, y: (this.price_max - this.price_min) / this.levels},
            yAxis: this.charts['main'].getDefaultAxisY()
          }
      );
      this.series['heatmap'].setWireframeStyle(emptyLine)
          .setFillStyle(new PalettedFill({
            lookUpProperty: 'value',
            lut: new LUT({
              interpolate: true,
              steps: [
                {value: 0, color: ColorRGBA(0, 0, 0, 8)},
                {value: p_med / 2, color: ColorRGBA(252, 221, 0, 64)},
                {value: p_med, color: ColorRGBA(252, 221, 0, 128)},
                {value: p_high, color: ColorRGBA(250, 0, 0, 200)},
              ]
            })
          }))
      if (this.interpolation === 0) {
        this.series['heatmap'].setIntensityInterpolation('disabled');
      } else {
        this.series['heatmap'].setIntensityInterpolation('bilinear');
      }

      // .setWireframeStyle(emptyLine)
      this.series['heatmap'].setCursorResultTableFormatter((builder, series, dataPoint) => builder
          .addRow('VOL', '', dataPoint.intensity.toFixed(1))
          .addRow('TSI:', '', String(Math.trunc(((this.timeStart * 1000) + dataPoint.x) / 1000 / 60 / this.tf)))
      )
      this.series['heatmap'].invalidateIntensityValues(new_book)
    },
    loadBook(ticker, time_start, time_end) {
      if (this.no_book === 1)
        return;
      const url_book = `${this.api}/book/hmlevels?ticker=${ticker}&tf=${this.tf}&time_start=${time_start}&time_end=${time_end}&pmin=${this.price_min}&pmax=${this.price_max}&levels=${this.levels}&db=${this.db}`
      let body = ''
      get(url_book, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              this.dataBook = {}
              const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
              data.forEach(el => {
                const x = el[0];// * this.tf * 60 * 1000;
                const y = el[1]
                const v = el[2]
                if (!(x in this.dataBook))
                  this.dataBook[x] = {}
                if (!(y in this.dataBook[x]))
                  this.dataBook[x][y] = v
                else
                  this.dataBook[x][y] += v
              })
              this.applyBooks()
            }.bind(this)
        )
      }.bind(this));
    },
    loadCandles(ticker, time_start, time_end) {
      // if (ticker.includes('_FTS_'))
      //   ticker = ticker.replace('_FTS_', '_SPOT_')
      const url_candles = `${this.api}/candles?ticker=${ticker}&tf=${this.tf}&time_start=${time_start}&time_end=${time_end}&db=${this.db}`
      let body = ''
      get(url_candles, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
              let tsArray = [];
              data.forEach(el => {
                tsArray.push(el[0])
              })
              tsArray.sort(function (a, b) {
                return a - b;
              });
              while (tsArray.length > this.historyLength) {
                tsArray.shift();
              }
              let prev = undefined;
              data.forEach(el => {
                if (tsArray.includes(el[0])) {
                  let ts = el[0];
                  let open = el[1];
                  let high = el[2];
                  let low = el[3];
                  let close = el[4];
                  let volume = el[5];

                  if (open > close) { // DOWN
                    if ((high - open) / (open - close) >= 3)
                      high = open;
                    if ((close - low) / (open - close) >= 3)
                      low = close;
                  } else if (open < close) { // UP
                    if ((high - close) / (close - open) >= 3)
                      high = close;
                    if ((open - low) / (close - open) >= 3)
                      low = open;
                  } else {
                    high = close;
                    low = close;
                  }
                  this.dataCandles.push([(ts - this.timeStart) * 1000, open, high, low, close]);
                  const cur = {x: (ts - this.timeStart) * 1000, y: volume};
                  // if (prev) {
                  //   this.dataVolumes.push({x: prev.x, y: cur.y})
                  // }
                  this.dataVolumes.push(cur);
                  prev = cur;
                }
              })
              // this.timeStart = tsArray.shift();
              // this.timeEnd = tsArray.pop();
              this.applyCandles()
            }.bind(this)
        )
      }.bind(this));
    },
    configureChart(chart) {
      chart.setTitleFillStyle(emptyFill)
      chart.setMouseInteractions(false)
      chart.disableAnimations()
    },
    configureX(chart) {
      chart.getDefaultAxisX().setAnimationZoom(undefined)
      chart.getDefaultAxisX().setTickStrategy(
          AxisTickStrategies.DateTime,
          (tickStrategy) => tickStrategy.setDateOrigin(new Date(this.timeStart * 1000))
              .setMajorTickStyle(visibleTicks => visibleTicks
                  .setLabelFont(font => font
                      .setSize(12)
                      .setWeight('lighter')
                  )
                  .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine)
              )
              .setMinorTickStyle(tickStrategy.getMajorTickStyle())
              .setMinorTickStyle(visibleTicks => visibleTicks
                  .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine))
      )
      chart.getDefaultAxisX()
          .setScrollStrategy(AxisScrollStrategies.fitting)
          .setChartInteractionFitByDrag(false)
          .setChartInteractionZoomByDrag(false)
          .setChartInteractionPanByDrag(false)
          .setChartInteractionZoomByWheel(false)
          .setAxisInteractionZoomByDragging(false)
          .setAxisInteractionZoomByWheeling(false)
          .setChartInteractions(false)
          .setMouseInteractions(false)

    },
    configureY(chart) {
      // https://stackoverflow.com/questions/59934089/how-to-disable-zooming-animation-on-loading-lightningchart-js
      chart.getDefaultAxisY().setAnimationZoom(undefined)
      let tickStrategy = chart.getDefaultAxisY().getTickStrategy()
      chart.getDefaultAxisY().setTickStrategy(
          // Use Numeric TickStrategy as Base.
          tickStrategy,
          // Use a mutator to modify the TickStrategy.
          (tickStrategy) => tickStrategy
              // Modify the Major Ticks for the TickStrategy.
              // Minor and Extreme TickStyles must be set separately.
              .setMajorTickStyle(visibleTicks => visibleTicks
                  .setLabelFont(font => font
                      .setSize(12)
                      .setWeight('lighter')
                  )
                  // .setTickStyle(emptyLine)
                  // .setGridStrokeStyle(emptyLine)
                  .setGridStrokeStyle(new SolidLine({
                    fillStyle: new SolidFill({color: ColorHEX('#00ff00').setA(80)}),
                    thickness: -1
                  }))
                  .setLabelFillStyle(new SolidFill({color: ColorHEX('#00ff00'), tickLength: 8}))
                  .setTickStyle(new SolidLine({fillStyle: new SolidFill({color: ColorHEX('#00ff00')}), thickness: -1}))
              )

              .setMinorTickStyle(tickStrategy.getMajorTickStyle())
              .setMinorTickStyle(visibleTicks => visibleTicks
                  // .setTickStyle(emptyLine)
                  .setGridStrokeStyle(emptyLine))
          // .setGridStrokeStyle(new SolidLine({
          //   fillStyle: new SolidFill({color: ColorHEX('#e3fc40').setA(80)}),
          //   thickness: -1
          // }))
          // .setLabelFillStyle(new SolidFill({color: ColorHEX('#e3fc40'), tickLength: 8}))
          // .setTickStyle(new SolidLine({fillStyle: new SolidFill({color: ColorHEX('#00ff00')}), thickness: -1})))
      )
      chart.getDefaultAxisY()
          .setAxisInteractionZoomByDragging(false)
          .setAxisInteractionZoomByWheeling(false)
          .setChartInteractions(false)
          .setMouseInteractions(false)
    },
    createDashBoard() {
      this.dashboard = lightningChart().Dashboard({
        container: `${this.chartId}`,
        numberOfColumns: 1,
        numberOfRows: 3 + this.pds.length,
        disableAnimations: true,
        theme: Themes.darkGreen,
      }).setSplitterStyle(new SolidLine({thickness: 0}))

      const dashboardHeightPx = this.dashboard.engine.container.getBoundingClientRect().height
      // console.log(Math.trunc(dashboardHeightPx / 4 * 2))
      // this.dashboard.setRowHeight(0, Math.trunc(dashboardHeightPx / 4 * 2))
      let rowHeight = Math.trunc(dashboardHeightPx / 4)
      this.dashboard.setHeight(rowHeight * (3 + this.pds.length))


      this.charts['main'] = this.dashboard.createChartXY({
        columnIndex: 0,
        rowIndex: 0,
        rowSpan: 2,
        defaultAxisY: {opposite: true,}
      })

      this.configureX(this.charts['main'])
      this.configureY(this.charts['main'])
      this.configureChart(this.charts['main'])

      let prediction_y = this.charts['main'].addAxisY({opposite: true})
          .setAxisInteractionZoomByDragging(false)
          .setAxisInteractionZoomByWheeling(false)
          .setChartInteractions(false)
          .setMouseInteractions(false)
          .setInterval(-0.1, 1.1, false, true)

      prediction_y.addConstantLine(false)
          .setValue(1)
          .setStrokeStyle(new SolidLine({
            thickness: 4,
            fillStyle: new SolidFill({color: ColorHEX('#FF6F3D')}).setA(100)
          }))
          .setMouseInteractions(false)

      prediction_y.addConstantLine(false)
          .setValue(0.7)
          .setStrokeStyle(new SolidLine({
            thickness: 4,
            fillStyle: new SolidFill({color: ColorHEX('#FF6F3D')}).setA(100)
          }))
          .setMouseInteractions(false)

      prediction_y.addConstantLine(false)
          .setValue(0.5)
          .setStrokeStyle(new SolidLine({
            thickness: 4,
            fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(100)
          }))
          .setMouseInteractions(false)
      this.P0Series = this.charts['main'].addPointSeries({yAxis: prediction_y})
      this.P1Series = this.charts['main'].addPointSeries({yAxis: prediction_y})
      this.P2Series = this.charts['main'].addPointSeries({yAxis: prediction_y})
      this.P3Series = this.charts['main'].addPointSeries({yAxis: prediction_y})

      this.P0Series.setPointFillStyle(new SolidFill({color: ColorHEX('#ffffff')}).setA(255))
      this.P1Series.setPointFillStyle(new SolidFill({color: ColorHEX('#42ff25')}).setA(255))
      this.P2Series.setPointFillStyle(new SolidFill({color: ColorHEX('#f00')}).setA(255))
      this.P3Series.setPointFillStyle(new SolidFill({color: ColorHEX('#ff6f3d')}).setA(255))


      this.charts['volume'] = this.dashboard.createChartXY({
        columnIndex: 0,
        rowIndex: 2,
        defaultAxisY: {opposite: true,}
      }).setTitleFillStyle(emptyFill)
      this.configureX(this.charts['volume'])
      this.configureY(this.charts['volume'])
      this.configureChart(this.charts['volume'])

      this.series['volume'] = this.charts['volume'].addAreaSeries({
        type: AreaSeriesTypes.Positive
      }).setName('Volume')
          .setCursorInterpolationEnabled(false)
          .setMouseInteractions(false)

      this.series['volume_cs'] = this.charts['volume'].addLineSeries().setName('Volume CS')
          .setCursorInterpolationEnabled(false)
          .setMouseInteractions(false)
          .setStrokeStyle(new SolidLine({
            thickness: 2,
            fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(80)
          }))

      this.series['candles'] = this.charts['main'].addOHLCSeries({positiveFigure: OHLCFigures.Candlestick})
          .setFigureAutoFitting(false)

      let row = 3;
      this.pds.forEach(pd => {
        this.charts[`rate_${pd}`] = this.dashboard.createChartXY({
          columnIndex: 0,
          rowIndex: row,
          defaultAxisY: {opposite: true,}
        })

        this.configureX(this.charts[`rate_${pd}`])
        this.configureY(this.charts[`rate_${pd}`])
        this.configureChart(this.charts[`rate_${pd}`])
        // this.charts[`rate_${pd}`].getDefaultAxisY().setInterval(-100, 100, false, true)

        // this.series[`rate_${pd}`] = this.charts[`rate_${pd}`].addLineSeries({
        //   individualLookupValuesEnabled: true,
        // })

        // this.series[`rate_${pd}_avg_slow`] = this.charts[`rate_${pd}`].addLineSeries({
        //   individualLookupValuesEnabled: true,
        // }).setStrokeStyle(new SolidLine({
        //   fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(255),
        //   thickness: 3,
        // }))

        this.series[`rate_${pd}_asks`] = this.charts[`rate_${pd}`].addLineSeries({
          individualLookupValuesEnabled: false,
        }).setStrokeStyle(new SolidLine({
          fillStyle: new SolidFill({color: ColorHEX('#ff0000')}).setA(255),
          thickness: 3,
        }))

        this.series[`rate_${pd}_bids`] = this.charts[`rate_${pd}`].addLineSeries({
          individualLookupValuesEnabled: false,
        }).setStrokeStyle(new SolidLine({
          fillStyle: new SolidFill({color: ColorHEX('#00ff00')}).setA(255),
          thickness: 3,
        }))

        // this.series[`rate_${pd}_avg_fast`] = this.charts[`rate_${pd}`].addLineSeries({
        //   individualLookupValuesEnabled: true,
        // }).setStrokeStyle(new SolidLine({
        //   fillStyle: new SolidFill({color: ColorHEX('#fcb90a')}).setA(255),
        //   thickness: 3,
        // }))

        // this.series[`rate_${pd}`].setStrokeStyle(new SolidLine({
        //   thickness: 2,
        //   fillStyle: new PalettedFill({
        //     lookUpProperty: 'value',
        //     lut: new LUT({
        //       interpolate: false,
        //       steps: [
        //         {value: -1, color: ColorRGBA(255, 0, 0)},
        //         {value: 1, color: ColorRGBA(0, 255, 0)},
        //       ]
        //     })
        //   })
        // }))

        //
        // this.charts[`rate_${pd}`].getDefaultAxisY().addConstantLine(false)
        //     .setValue(30)
        //     .setStrokeStyle(new SolidLine({
        //       thickness: 4,
        //       fillStyle: new SolidFill({color: ColorHEX('#00ff00')}).setA(80)
        //     })).setMouseInteractions(false)
        //
        // this.charts[`rate_${pd}`].getDefaultAxisY().addConstantLine(false)
        //     .setValue(-30)
        //     .setStrokeStyle(new SolidLine({
        //       thickness: 4,
        //       fillStyle: new SolidFill({color: ColorHEX('#00ff00')}).setA(80)
        //     })).setMouseInteractions(false)
        //
        //
        // this.charts[`rate_${pd}`].getDefaultAxisY().addConstantLine(false)
        //     .setValue(50)
        //     .setStrokeStyle(new SolidLine({
        //       thickness: 4,
        //       fillStyle: new SolidFill({color: ColorHEX('#fff600')}).setA(80)
        //     })).setMouseInteractions(false)
        // this.charts[`rate_${pd}`].getDefaultAxisY().addConstantLine(false)
        //     .setValue(-50)
        //     .setStrokeStyle(new SolidLine({
        //       thickness: 4,
        //       fillStyle: new SolidFill({color: ColorHEX('#fff600')}).setA(80)
        //     })).setMouseInteractions(false)
        //
        //
        // this.charts[`rate_${pd}`].getDefaultAxisY().addConstantLine(false)
        //     .setValue(70)
        //     .setStrokeStyle(new SolidLine({
        //       thickness: 4,
        //       fillStyle: new SolidFill({color: ColorHEX('#ff1c1c')}).setA(80)
        //     })).setMouseInteractions(false)
        //
        // this.charts[`rate_${pd}`].getDefaultAxisY().addConstantLine(false)
        //     .setValue(-70)
        //     .setStrokeStyle(new SolidLine({
        //       thickness: 4,
        //       fillStyle: new SolidFill({color: ColorHEX('#ff1c1c')}).setA(80)
        //     })).setMouseInteractions(false)

        const watermark = this.charts[`rate_${pd}`]
            .addUIElement(UIElementBuilders.TextBox)
            .setBackground((bg) => bg.setFillStyle(emptyFill).setStrokeStyle(emptyLine))
            .setText(pd.toString())
            .setMouseInteractions(false)
            // The position is in percentages (0-100), with Y position going from bottom
            // of the chart container to top.
            .setPosition({x: 48, y: 45})
            .setOrigin({x: -1, y: -1})
            // Use the alpha channel of the color to set the FillStyle of the text to semi-transparent.
            .setTextFillStyle(new SolidFill({color: ColorHEX('#bdafaf')}))
            .setTextFont((font) => font.setSize(54).setFamily('sans-serif'))
        row++;
      })

      let cc = 0;
      Object.values(this.charts).forEach(chart => {
        cc++;
        const axisX = chart.getDefaultAxisX()
        if (cc % 3 === 0 || cc === 2 + this.pds.length) {
          axisX
              .setTickStrategy(AxisTickStrategies.DateTime)
        } else {
          axisX
              .setTickStrategy(AxisTickStrategies.Empty)
              .setStrokeStyle(emptyLine)
        }
      })

      const constantLinesX = Object.values(this.charts)
          .map(chart => chart.getDefaultAxisX().addConstantLine()
              .setMouseInteractions(false))

      Object.values(this.charts).forEach((chart, i) => {
        chart.onSeriesBackgroundMouseMove((_, event) => {
          // Get mouse location in web page
          const mouseLocationClient = {
            x: event.clientX,
            y: event.clientY,
          }
          const mouseLocationEngine = chart.engine.clientLocation2Engine(
              mouseLocationClient.x,
              mouseLocationClient.y
          )
          // Translate mouse location to X Axis.
          const mouseLocationAxisX = translatePoint(mouseLocationEngine, chart.engine.scale, {
            x: chart.getDefaultAxisX(),
            y: chart.getDefaultAxisY(),
          }).x
          constantLinesX.forEach(line => line.setValue(mouseLocationAxisX))
        })
      })
      this.addMenu();
      this.addKbShortcuts();

      let last_x = 8;
      const button_save = this.charts['main'].addUIElement(UIElementBuilders.ButtonBox.setBackground(UIBackgrounds.Rectangle))
          .setPosition(translatePoint({
            x: last_x,
            y: this.charts['main'].engine.container.getBoundingClientRect().height - 8
          }, this.charts['main'].engine.scale, this.charts['main'].uiScale))
          .setOrigin(UIOrigins.LeftTop)
          .setText('Save')
          .setPadding({top: 5, right: 20, bottom: 5, left: 20})
          .setButtonOffSize(0)
          .setButtonOnSize(0)
          .setDraggingMode(UIDraggingModes.notDraggable)
          .setBackground(bg => bg
              .setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX('#f00'))))
          )
          .onMouseClick((event) => {
            this.saveMarkup()
          });
      this.charts['main'].onSeriesBackgroundMouseClick((obj, event) => {
        const a_key = parseInt(this.activeKey);
        if (a_key >= 0 && a_key <= 3) {
          const mouseLocationEngine = this.charts['main'].engine.clientLocation2Engine(event.clientX, event.clientY)
          const mouseLocationAxis = translatePoint(mouseLocationEngine, this.charts['main'].engine.scale, {
            x: this.charts['main'].getDefaultAxisX(),
            y: this.charts['main'].getDefaultAxisY()
          })

          let tsi = Math.trunc(((this.timeStart * 1000) + mouseLocationAxis.x) / 1000 / 60 / this.tf)
          this.addLine(tsi, a_key);
        }

      })
      this.loadCandles(this.ticker, this.timeStart, this.timeEnd)
    },
  },
  async beforeMount() {
    // Generate random ID to us as the containerId for the chart and the target div id
    this.chartId = Math.trunc(Math.random() * 1000000)
    this.tf = !(this.$route.query.tf == null) ? parseInt(this.$route.query.tf) : 60;
    this.historyLength = !(this.$route.query.hl == null) ? parseInt(this.$route.query.hl) : 100;
    this.price_min = !(this.$route.query.pmin == null) ? parseFloat(this.$route.query.pmin) : 0;
    this.price_max = !(this.$route.query.pmax == null) ? parseFloat(this.$route.query.pmax) : 0;
    this.boundary_percent = !(this.$route.query.bp == null) ? parseInt(this.$route.query.bp) : 10;
    this.levels = !(this.$route.query.levels == null) ? parseInt(this.$route.query.levels) : 100;
    const currentTSI = Math.round(new Date().getTime() / 1000 / this.tf / 60);
    this.timeStart = this.$route.query.time_start || (currentTSI - this.historyLength) * this.tf * 60;
    this.timeEnd = this.$route.query.time_end || (currentTSI + 1) * this.tf * 60;
    this.tsi = !(this.$route.query.tsi == null) ? parseInt(this.$route.query.tsi) : 0;
    this.project_id = !(this.$route.query.pid == null) ? parseInt(this.$route.query.pid) : 0;
    this.vmax = !(this.$route.query.vmax == null) ? parseInt(this.$route.query.vmax) : 0;
    this.vmin = !(this.$route.query.vmin == null) ? parseInt(this.$route.query.vmin) : 0;
    this.interpolation = !(this.$route.query.ip == null) ? parseInt(this.$route.query.ip) : 0;
    this.no_book = !(this.$route.query.nb == null) ? parseInt(this.$route.query.nb) : 0;
    if (!(this.$route.query.pds == null)) {
      this.pds = this.$route.query.pds.split(',').map(x => parseFloat(x))
    }
    this.prediction_min_level = !(this.$route.query.pminl == null) ? parseFloat(this.$route.query.pminl) : 0.0;
    this.prediction_max_level = !(this.$route.query.pmaxl == null) ? parseFloat(this.$route.query.pmaxl) : 1.0;
    this.reload = !(this.$route.query.reload == null) ? parseInt(this.$route.query.reload) : 0;
    if (!(this.$route.query.ticker == null)) {
      this.ticker = this.$route.query.ticker
    }

    if (!(this.$route.query.title == null)) {
      document.title = this.$route.query.title;
    } else {
      document.title = this.ticker;
    }
    if (this.tsi > 0) {
      this.timeStart = (this.tsi - Math.round(this.historyLength / 2) + 2) * this.tf * 60;
      this.timeEnd = (this.tsi + Math.round(this.historyLength / 2)) * this.tf * 60;
    }
    this.db = 'trevax';
    if (this.$route.query.db === 'trevax_metrics' || this.$route.query.db === 'scoins')
      this.db = this.$route.query.db;
  },
  mounted() {
    // Chart can only be created when the component has mounted the DOM because
    // the chart needs the element with specified containerId to exist in the DOM
    this.createDashBoard()
    if (this.reload > 0) {
      setTimeout(function () {
        window.location.reload();
      }, 1000 * this.reload * 60);
    }
  },
  beforeDestroy() {
    // "dispose" should be called when the component is unmounted to free all the resources used by the chart
    if (this.chart !== null)
      this.charts['main'].dispose()
  }
}
</script>
