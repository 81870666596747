<template>
  <!-- Allocate chart inside div container -->
  <div style="height: 100%; width: 100%;">
    <div id="controls"
         style="height: 3%; color:Blue; font-size: 20px; text-align: center">
      <div style="display: flex; float: left">
        <a href="" id="data_prev" style="color: green"><<<</a>
      </div>
      <div style="display: flex; float: right">
        <a href="" id="data_next" style="color: green">>>></a>
      </div>
      <div id="div_timeframes"
           style="height: auto; background: black; color:Blue; font-size: 20px; text-align: center"></div>
    </div>
    <div :id="chartId" style="height: 97%"></div>
  </div>
</template>

<script>
import {
  AxisScrollStrategies,
  AxisTickStrategies,
  emptyFill,
  lightningChart,
  OHLCFigures,
  SolidLine,
  PalettedFill,
  Themes,
  LUT,
  ColorRGBA,
  emptyLine,
  synchronizeAxisIntervals,
  AutoCursorModes,
  translatePoint,
  UIOrigins,
  UILayoutBuilders,
  UIElementBuilders, SolidFill, ColorHEX, ImageFill, ImageFitMode,
} from '@arction/lcjs'
import {get} from "https";
import axios from "axios";
import FileSaver from 'file-saver';

const request = require('request');


const percentile = require('stats-percentile');

function get_page(url) {
  return new Promise((resolve, reject) => {
    request(url, (error, response, body) => {
      if (error) reject(error);
      if (response.statusCode !== 200) {
        reject('Invalid status code <' + response.statusCode + '>');
      }
      resolve(body);
    });
  });
}

export default {
  name: 'DashBoard',
  // props: ['points'],
  data() {
    const license_key = typeof process.env.VUE_APP_LICENSE === 'undefined' ? '' : process.env.VUE_APP_LICENSE;
    this.lcjs = lightningChart({
      license: license_key,
      overrideInteractionMouseButtons: {
        chartXYPanMouseButton: 0,
        chartXYRectangleZoomFitMouseButton: 2,
      },
    })

    // Add the chart to the data in a way that Vue will not attach it's observers to it.
    // If the chart variable would be added in the return object, Vue would attach the observers and 
    // every time LightningChart JS made a change to any of it's internal variables, vue would try to observe the change and update.
    // Observing would slow down the chart a lot.
    this.tf = 60
    this.tickers = []
    this.charts = {}
    this.candles = {}
    this.heatmaps = {}
    this.opts = {}
    this.cvds = {}
    this.cvd_yaxes = {}
    this.cvd_series = {}
    this.timeStart = null
    this.dashboard = null
    this.dataBooks = {}
    this.dataCandles = {}
    this.api = 'https://api.trevax.com'
    this.boundary_percent = 10
    this.levels = 100
    this.endX = 0
    this.yMin = 0
    this.yMax = 0
    this.tsi = 0
    this.pub = false
    this.wm = false
    this.base = null
    this.cols = 3
    this.reload = 0;
    this.oldStyle = null;
    this.db = 'trevax';
    return {
      chartId: null,
    }
  },
  methods: {
    addKbShortcuts() {
      window.document.onkeyup = function (e) {
        if (e.code === 'KeyM' && e.shiftKey) {
          this.pub = !this.pub;
          if (this.pub) {
            window.document.getElementById('controls').style.display = 'none';
            window.document.getElementById(this.chartId).style.height = '100%';
            this.dashboard.engine.layout();
          } else {
            window.document.getElementById('controls').style.display = 'block';
            window.document.getElementById(this.chartId).style.height = '97%';
            this.dashboard.engine.layout();
          }
        } else if (e.code === 'KeyW' && e.shiftKey) {
          this.wm = !this.wm;
          if (this.wm) {
            if (null === this.oldStyle) {
              this.oldStyle = this.charts[this.tickers[0]].getSeriesBackgroundFillStyle();
            }
            for (const [ticker, chart] of Object.entries(this.charts)) {
              this.addWatermark(chart)
            }
          } else {
            for (const [ticker, chart] of Object.entries(this.charts)) {
              chart.setSeriesBackgroundFillStyle(this.oldStyle)
            }
          }
        }
      }.bind(this);
    },
    addWatermark(chart) {
      const image = new Image()
      image.crossOrigin = ''
      image.src = '/chart_logo.png'
      image.onload = () => {
        chart.setSeriesBackgroundFillStyle(
            new ImageFill({
              source: image,
              fitMode: ImageFitMode.Center,
            }),
        )
      }
    },
    addMenu() {
      let div_timeframes = document.getElementById('div_timeframes')
      div_timeframes.innerHTML = "";

      {
        let container = document.createElement("span");
        let text = document.createTextNode("TF:");
        container.appendChild(text);
        container.style.color = "white";
        div_timeframes.appendChild(container);
      }

      [1, 5, 10, 15, 30, 60, 120, 180, 240, 720, 1440].forEach(tf => {
        let a = document.createElement('a');
        let link = document.createTextNode(tf.toString());
        a.appendChild(link);
        a.href = "#";
        if (this.tf === tf)
          a.style.color = 'orange'
        else
          a.style.color = 'blue'
        a.style.margin = '5px'
        a.id = `TF_${tf}`
        a.onclick = function () {
          let tf = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('tf', tf);
          window.open(href.toString(), "_self")
          return false
        }
        div_timeframes.appendChild(a);
      });


      let sizes = [50, 100, 200, 500, 700, 1000];

      {
        let container = document.createElement("span");
        let text = document.createTextNode("HL:");
        container.appendChild(text);
        container.style.color = "white";
        div_timeframes.appendChild(container);
      }

      sizes.forEach(size => {
        let href = new URL(document.URL);
        let a = document.createElement('a');
        let link = document.createTextNode(size.toString());
        a.appendChild(link);
        a.href = "#";
        let hl = 0;
        if (href.searchParams.has('hl')) {
          hl = parseInt(href.searchParams.get('hl'))
        }
        if (size === hl)
          a.style.color = 'orange'
        else
          a.style.color = '#00ff04'

        a.style.margin = '5px'
        a.id = `hl_${size}`
        a.onclick = function () {
          let hl = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('hl', hl);
          window.open(href.toString(), "_self")
          return false
        }
        div_timeframes.appendChild(a);
      });

      {
        let container = document.createElement("span");
        let text = document.createTextNode("LEVELS:");
        container.appendChild(text);
        container.style.color = "white";
        div_timeframes.appendChild(container);
      }
      sizes.forEach(size => {
        let href = new URL(document.URL);
        let a = document.createElement('a');
        let link = document.createTextNode(size.toString());
        a.appendChild(link);
        a.href = "#";
        let levels = 0;
        if (href.searchParams.has('levels')) {
          levels = parseInt(href.searchParams.get('levels'))
        }
        if (size === levels)
          a.style.color = 'orange'
        else
          a.style.color = '#fff600'

        a.style.margin = '5px'
        a.id = `levels_${size}`
        a.onclick = function () {
          let levels = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('levels', levels);
          window.open(href.toString(), "_self")
          return false
        }
        div_timeframes.appendChild(a);
      });

      {
        let container = document.createElement("span");
        let text = document.createTextNode("BP:");
        container.appendChild(text);
        container.style.color = "white";
        div_timeframes.appendChild(container);
      }
      [1, 3, 5, 10, 15, 25, 30, 40, 50].forEach(prc => {
        let href = new URL(document.URL);
        let a = document.createElement('a');
        let link = document.createTextNode(prc.toString());
        a.appendChild(link);
        a.href = "#";

        let bp = 0;
        if (href.searchParams.has('bp')) {
          bp = parseInt(href.searchParams.get('bp'))
        }
        if (prc === bp)
          a.style.color = 'orange'
        else
          a.style.color = '#f028ff'
        a.style.margin = '5px'
        a.id = `bp_${prc}`
        a.onclick = function () {
          let bp = a.id.split('_')[1]
          let href = new URL(document.URL);
          href.searchParams.set('bp', bp);
          window.open(href.toString(), "_self")
          return false
        }
        div_timeframes.appendChild(a);
      });


      {
        let href = new URL(document.URL);
        let a = document.createElement('a');
        let link = document.createTextNode('SAVE');
        a.appendChild(link);
        a.href = "#";

        let bp = 0;
        if (href.searchParams.has('bp')) {
          bp = parseInt(href.searchParams.get('bp'))
        }

        a.style.color = '#f028ff'
        a.style.margin = '5px'
        a.onclick = function () {
          let ts = Math.trunc(Date.now() / 1000)
          let postfix = 'orig'
          if (this.wm) {
            postfix = 'wm'
          }
          let blob = new Blob([this.dashboard.engine.captureFrame()], {type: "image/png"});
          FileSaver.saveAs(blob, `${ts}_DB_${postfix}`);
          return false
        }.bind(this)
        div_timeframes.appendChild(a);

      }

      let href = new URL(document.URL);
      let link_prev = document.getElementById('data_prev')
      let link_next = document.getElementById('data_next')
      href.searchParams.delete('time_start')
      href.searchParams.delete('time_end')

      let ctsi = 0;
      if (href.searchParams.has('tsi')) {
        ctsi = parseInt(href.searchParams.get('tsi'));
      } else {
        ctsi = Math.round(new Date().getTime() / 1000 / this.tf / 60);
      }

      const tsi_prev = ctsi - Math.trunc(this.historyLength / 4 * 3)
      const tsi_next = ctsi + Math.trunc(this.historyLength / 4 * 3)

      href.searchParams.set('tsi', String(tsi_prev))
      link_prev.href = href

      href.searchParams.set('tsi', String(tsi_next))
      link_next.href = href

    },
    applyCandles(ticker) {
      let price_min = 0.0;
      let price_max = 0.0;
      let yMin = 0.0;
      let yMax = 0.0;
      this.dataCandles[ticker].forEach(candle => {
        const high = candle[2]
        const low = candle[3]

        if (yMin === 0 || yMin > low)
          yMin = low
        if (yMax < high)
          yMax = high
      })
      this.candles[ticker].clear()
      this.candles[ticker].add(this.dataCandles[ticker])

      this.cvd_series[ticker].clear()
      this.cvd_series[ticker].add(this.cvds[ticker])

      if (this.boundary_percent > 0) {
        const book_boundary = yMax / 100 * this.boundary_percent
        price_max = yMax + book_boundary
        price_min = yMin - book_boundary
        price_min < 0 ? 0 : price_min
      }

      this.opts[ticker]['price_min'] = price_min;
      this.opts[ticker]['price_max'] = price_max;

      (async () => {
        let axisY = this.charts[ticker].getDefaultAxisY()
        axisY.setInterval(price_min, price_max, false, true)

        // let axisX = this.charts[ticker].getDefaultAxisX()
        // axisX.setInterval(this.timeStart * 1000, this.timeEnd * 1000)
      })();
      if (this.tsi > 0) {
        const tsiLine = this.charts[ticker].getDefaultAxisX().addConstantLine(false)
            .setName('tsi')
            .setValue(((this.tsi * this.tf * 60) - this.opts[ticker]['timeStart']) * 1000)
            .setStrokeStyle(new SolidLine({
              thickness: 4,
              fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(60)
            }))
            .setMouseInteractions(false)
      }

      this.loadBook(ticker, this.opts[ticker]['timeStart'], this.opts[ticker]['timeEnd'])
    },
    applyBook(ticker) {
      let vol_all = []
      let max_vol = 0
      let new_book = Array(this.historyLength).fill(0.0).map(() => Array(this.levels))
      let books = this.dataBooks[ticker];
      for (const [ts, data] of Object.entries(books)) {
        for (const [price, volume] of Object.entries(data)) {
          if (price >= this.levels || ts >= this.historyLength) {
            continue;
          }
          new_book[ts][price] = volume;
          if (volume > max_vol)
            max_vol = volume
          vol_all.push(volume)
        }
      }

      const p_low = percentile(vol_all, 75)
      const p_high = percentile(vol_all, 99)
      const p_med = ((p_high - p_low) / 2) + p_low

      const xStep = this.tf * 60 * 1000

      // HEATMAP
      const chart = this.charts[ticker];
      this.heatmaps[ticker] = chart.addHeatmapGridSeries(
          {
            columns: this.historyLength,
            rows: this.levels,
            dataOrder: 'columns',
            start: {x: 0, y: this.opts[ticker]['price_min']},
            // end: {x: this.timeEnd * 1000, y: this.opts[ticker]['price_max']},
            step: {x: xStep, y: (this.opts[ticker]['price_max'] - this.opts[ticker]['price_min']) / this.levels}
          }
      ).setWireframeStyle(emptyLine);

      // // this.heatmaps[ticker].setIntensityInterpolation('disabled')
      this.heatmaps[ticker].setIntensityInterpolation('bilinear')
          .setFillStyle(new PalettedFill({
            lookUpProperty: 'value',
            lut: new LUT({
              interpolate: true,
              steps: [
                {value: 0, color: ColorRGBA(0, 0, 0, 8)},
                {value: p_med / 2, color: ColorRGBA(252, 221, 0, 64)},
                {value: p_med, color: ColorRGBA(252, 221, 0, 128)},
                {value: p_high, color: ColorRGBA(250, 0, 0, 200)},
              ]
            })
          }))
          // .setWireframeStyle(emptyLine)
          .setCursorResultTableFormatter((builder, series, dataPoint) => builder
              .addRow('V', '', dataPoint.intensity.toFixed(1))
              // .addRow('Time:', '', chart.getDefaultAxisX().formatValue(dataPoint.x))
              .addRow('Price:', '', chart.getDefaultAxisY().formatValue(dataPoint.y))
              .addRow('TSI:', '', String(Math.trunc(((this.opts[ticker]['timeStart'] * 1000) + dataPoint.x) / 1000 / 60 / this.tf)))
          )
      this.heatmaps[ticker].invalidateIntensityValues(new_book)
    },
    loadBook(ticker, time_start, time_end) {
      const url_book = `${this.api}/book/hmlevels?ticker=${ticker}&tf=${this.tf}&time_start=${time_start}&time_end=${time_end}&pmin=${this.opts[ticker]['price_min']}&pmax=${this.opts[ticker]['price_max']}&levels=${this.levels}&db=${this.db}`
      let body = ''
      get(url_book, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              let books = {};
              const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
              data.forEach(el => {
                const x = el[0];
                const y = el[1];
                const v = el[2];
                if (!(x in books))
                  books[x] = {};
                if (!(y in books[x]))
                  books[x][y] = v;
                else
                  books[x][y] += v;
              })
              this.dataBooks[ticker] = books;
              this.applyBook(ticker);
            }.bind(this)
        )
      }.bind(this));
    },
    loadCandles(ticker, time_start, time_end) {
      this.dataCandles[ticker] = [];
      this.cvds[ticker] = [];
      const url_candles = `${this.api}/candles?ticker=${ticker}&tf=${this.tf}&time_start=${time_start}&time_end=${time_end}&db=${this.db}`
      let body = ''
      get(url_candles, function (res) {
        res.on('data', function (chunk) {
          body += chunk;
        });
        res.on('end', function () {
              const data = JSON.parse('[' + body.replace(/\]\[/g, "],[") + ']')
              let tsArray = [];
              data.forEach(el => {
                tsArray.push(el[0])
              })
              tsArray.sort(function (a, b) {
                return a - b;
              });
              while (tsArray.length > this.historyLength) {
                tsArray.shift();
              }
              this.opts[ticker] = {}
              if (tsArray.length < this.historyLength) {
                this.opts[ticker]['timeStart'] = this.timeStart;
                this.opts[ticker]['timeEnd'] = this.timeEnd;
              } else {
                this.opts[ticker]['timeStart'] = tsArray.shift();
                this.opts[ticker]['timeEnd'] = tsArray.pop();
              }
              let cvd = 0;
              data.forEach(el => {
                if (tsArray.includes(el[0])) {
                  let ts = el[0];
                  let open = el[1];
                  let high = el[2];
                  let low = el[3];
                  let close = el[4];
                  const vdiff = el[6] - el[7];
                  cvd += vdiff;

                  if (open > close) { // DOWN
                    if ((high - open) / (open - close) >= 3)
                      high = open;
                    if ((close - low) / (open - close) >= 3)
                      low = close;
                  } else if (open < close) { // UP
                    if ((high - close) / (close - open) >= 3)
                      high = close;
                    if ((open - low) / (close - open) >= 3)
                      low = open;
                  } else {
                    high = close;
                    low = close;
                  }
                  this.dataCandles[ticker].push([(ts - this.timeStart) * 1000, open, high, low, close]);
                  this.cvds[ticker].push({x: (ts - this.timeStart) * 1000, y: cvd});
                }
              })
              this.applyCandles(ticker)
            }.bind(this)
        )
      }.bind(this));
    },
    createDashBoard() {
      this.addMenu();
      let rows = Math.trunc(this.tickers.length / this.cols);

      if (this.tickers.length > this.cols * rows)
        rows++;

      this.dashboard = lightningChart()
          .Dashboard({
            container: `${this.chartId}`,
            numberOfColumns: this.cols,
            numberOfRows: rows,
            disableAnimations: true,
            theme: Themes.darkGreen,
          })
          .setSplitterStyle(new SolidLine({thickness: 2}))

      const dashboardHeightPx = this.dashboard.engine.container.getBoundingClientRect().height

      let rowHeight = Math.trunc(dashboardHeightPx / 3)

      if (rows === 1) {
        rowHeight = dashboardHeightPx
      } else if (rows === 2) {
        rowHeight = Math.trunc(dashboardHeightPx / 2)
      } else {
        rowHeight = Math.trunc(dashboardHeightPx / 3)
      }
      this.dashboard.setHeight(rowHeight * rows)
      for (let row = 0; row < rows; row++) {
        this.dashboard.setRowHeight(row, rowHeight)
      }

      this.charts = {};
      this.candles = {};

      let col = 0;
      let row = 0;
      let xaxes = [];
      for (const ticker_info of this.tickers) {
        let ticker;
        if (typeof ticker_info === 'string' || ticker_info instanceof String) {
          ticker = ticker_info;
        } else {
          ticker = ticker_info['ticker']
        }

        this.charts[ticker] = this.dashboard
            .createChartXY({
              columnIndex: col,
              rowIndex: row,
              defaultAxisY: {opposite: true,}
            })
            .setTitle(ticker)
            .setTitleMarginTop(0)
            .setTitleMarginBottom(0)
            .setPadding(0)
            .setMouseInteractions(false)
            .setAutoCursor((autoCursor) => autoCursor.disposeTickMarkerX().disposeTickMarkerY().setAutoFitStrategy(undefined))
            .setAnimationsEnabled(false)

        // CVD
        this.cvd_yaxes[ticker] = this.charts[ticker].addAxisY({opposite: true})
            .setTickStrategy(AxisTickStrategies.Empty)
            .setMouseInteractions(false)
            .setAnimationZoom(undefined)
            .setStrokeStyle(emptyLine)
            .setNibStyle(emptyLine)
        this.cvd_series[ticker] = this.charts[ticker].addLineSeries({
          yAxis: this.cvd_yaxes[ticker],
          individualLookupValuesEnabled: false,
        }).setStrokeStyle(new SolidLine({
          fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(255),
          thickness: 3,
        }))


        // let link_bnf = '';
        // if (ticker['FTS'] === 1) {
        //   link_bnf = `https://www.binance.com/ru/futures/${ticker_info['symbol']}`;
        // }
        // let link_tv = `https://www.tradingview.com/chart/?symbol=BINANCE:${ticker_info['symbol']}&interval=15`;
        // let link_bns = `https://www.binance.com/ru/trade/${ticker_info['base']}_${ticker_info['quote']}?layout=pro`;
        // let link_tx = `https://charts.trevax.com/hm?ticker=${ticker}&tf=15&bp=15&hl=100&levels=100`;
        // let title = `${ticker}   ${link_tv}   ${link_bns}   ${link_bnf}   ${link_tx}`;
        // this.charts[ticker].setTitle(title)
        if (typeof ticker_info === 'string' || ticker_info instanceof String) {
        } else {
          if (ticker_info['FTS'] === 1) {
            this.charts[ticker].setTitleFillStyle(new SolidFill({color: ColorHEX('#42ff25')}))
          }
        }
        const axisX = this.charts[ticker]
            .getDefaultAxisX()
            .setAnimationZoom(undefined)
            .setTickStrategy(AxisTickStrategies.Empty)
            // .setTickStrategy(
            //     AxisTickStrategies.DateTime,
            //     (tickStrategy) => tickStrategy.setDateOrigin(new Date(this.opts[ticker]['timeStart'] * 1000))
            // )
            .setMouseInteractions(false)
            .setScrollStrategy(AxisScrollStrategies.fitting)
            .setStrokeStyle(emptyLine)
            .setNibStyle(emptyLine)
        //     .setScrollStrategy(undefined)
        //     .setChartInteractionFitByDrag(false)
        //     .setChartInteractionZoomByDrag(false)
        //     .setChartInteractionPanByDrag(false)
        //     .setChartInteractionZoomByWheel(false)
        //     .setAxisInteractionZoomByDragging(false)
        //     .setAxisInteractionZoomByWheeling(false)
        //     .setChartInteractions(false)
        const axisY = this.charts[ticker]
            .getDefaultAxisY()
            .setTickStrategy(AxisTickStrategies.Empty)
            .setMouseInteractions(false)
            .setAnimationZoom(undefined)
            .setStrokeStyle(emptyLine)
            .setNibStyle(emptyLine)
        // .setAxisInteractionZoomByDragging(false)
        // .setAxisInteractionZoomByWheeling(false)
        // .setChartInteractions(false)
        xaxes.push(axisX)
        this.candles[ticker] = this.charts[ticker].addOHLCSeries({positiveFigure: OHLCFigures.Candlestick}).setFigureAutoFitting(false);
        this.loadCandles(ticker, this.timeStart, this.timeEnd)
        col++;
        if (col === this.cols) {
          col = 0;
          row++;
        }
      }

      synchronizeAxisIntervals(...xaxes)
      const xTicks = xaxes.map((axisx) => axisx.addCustomTick().dispose())

      this.addKbShortcuts();

      // Object.values(this.charts).forEach((chart) => {
      //   // chart.setAutoCursorMode(AutoCursorModes.disabled)
      //   chart.onSeriesBackgroundMouseMove((_, event) => {
      //     const mouseLocationEngine = chart.engine.clientLocation2Engine(event.clientX, event.clientY)
      //     const mouseLocationAxisX = translatePoint(mouseLocationEngine, chart.engine.scale, {
      //       x: chart.getDefaultAxisX(),
      //       y: chart.getDefaultAxisY()
      //     }).x
      //     xTicks.forEach((xTick) => xTick
      //         .restore()
      //         .setValue(mouseLocationAxisX)
      //     )
      //   })
      //
      // if (this.tsi > 0) {
      //   const tsiLine = chart.getDefaultAxisX().addConstantLine(false)
      //       .setName('tsi')
      //       .setValue(this.tsi * this.tf * 60 * 1000)
      //       .setStrokeStyle(new SolidLine({
      //         thickness: 4,
      //         fillStyle: new SolidFill({color: ColorHEX('#ffffff')}).setA(60)
      //       }))
      //       .setMouseInteractions(false)
      // }
      // })
    },
    async beforeMnt() {
      // Generate random ID to us as the containerId for the chart and the target div id
      this.chartId = Math.trunc(Math.random() * 1000000)
      this.tf = !(this.$route.query.tf == null) ? parseInt(this.$route.query.tf) : 60;
      this.historyLength = !(this.$route.query.hl == null) ? parseInt(this.$route.query.hl) : 100;
      this.boundary_percent = !(this.$route.query.bp == null) ? parseInt(this.$route.query.bp) : 10;
      this.levels = !(this.$route.query.levels == null) ? parseInt(this.$route.query.levels) : 100;
      const currentTSI = Math.round(new Date().getTime() / 1000 / this.tf / 60);
      this.timeStart = this.$route.query.time_start || (currentTSI - this.historyLength) * this.tf * 60;
      this.timeEnd = this.$route.query.time_end || (currentTSI + 1) * this.tf * 60;
      this.tsi = !(this.$route.query.tsi == null) ? parseInt(this.$route.query.tsi) : 0;
      this.base = !(this.$route.query.base == null) ? this.$route.query.base : null;
      this.cols = !(this.$route.query.cols == null) ? parseInt(this.$route.query.cols) : 3;
      this.reload = !(this.$route.query.reload == null) ? parseInt(this.$route.query.reload) : 0;
      this.db = 'trevax';
      if (this.$route.query.db === 'trevax_metrics' || this.$route.query.db === 'scoins')
        this.db = this.$route.query.db;
      if (!(this.$route.query.title == null)) {
        document.title = this.$route.query.title;
      } else {
        document.title = 'DB';
      }
      if (!(this.$route.query.tickers == null)) {
        this.tickers = this.$route.query.tickers.split(',')
      } else {
        this.tickers = [];
      }
      if (!(this.$route.query.products == null)) {
        let products = this.$route.query.products.split(',');
        for (const product of products) {
          const url_tickers = `${this.api}/tools/get_tickers_by_product?product=${product}&db=${this.db}`;
          let tmp_tickers = JSON.parse(await get_page(url_tickers));
          if (tmp_tickers.length > 0) {
            this.tickers.push(...tmp_tickers);
          }
        }
      }
      if (this.tsi > 0) {
        this.timeStart = (this.tsi - Math.round(this.historyLength / 2) + 2) * this.tf * 60;
        this.timeEnd = (this.tsi + Math.round(this.historyLength / 2)) * this.tf * 60;
      }
    },
  },


  async mounted() {
    // Chart can only be created when the component has mounted the DOM because
    // the chart needs the element with specified containerId to exist in the DOM
    // this.createChart()
    await this.beforeMnt();
    let tickers_cnt = this.tickers.length;
    if (tickers_cnt < this.cols) {
      this.cols = tickers_cnt;
    }
    this.createDashBoard();
    if (this.reload > 0) {
      setTimeout(function () {
        window.location.reload();
      }, 1000 * this.reload * 60);
    }
  },
  beforeDestroy() {
    // "dispose" should be called when the component is unmounted to free all the resources used by the chart
    if (this.chart !== null)
      this.dashboard.dispose()
  }
}
</script>
